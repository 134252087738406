import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-6" }
const _hoisted_2 = { class: "w-[294px] rounded-[8px] mx-[24px]" }
const _hoisted_3 = { class: "flex p-[16px]" }
const _hoisted_4 = { class: "ml-[10px]" }
const _hoisted_5 = { class: "text-[18px] leading-[28px] font-semibold" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "text-[16px] text-[#909399] leading-[24px] font-light" }
const _hoisted_8 = { class: "flex flex-col" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_avatar, {
          size: 60,
          src: $setup.userStore.avatar
        }, null, 8 /* PROPS */, ["src"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              href: '/profile/' + $setup.userStore.username
            }, _toDisplayString($setup.userStore.nickname || $setup.userStore.username), 9 /* TEXT, PROPS */, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, "@" + _toDisplayString($setup.userStore.username), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createCommentVNode(" profile "),
        _createElementVNode("a", {
          href: "/settings/profile",
          class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/profile')])
        }, _toDisplayString(_ctx.$t('profile.menu.profile')), 3 /* TEXT, CLASS */),
        _createCommentVNode(" <div class=\"p-[16px] hover:bg-[#EBEDF0] border-b border-[#DCDFE6] text-[18px] text-[#606266] leading-[26px] opacity-40\"\n               :class=\"menuClass('/settings/account')\"\n          >\n            {{ $t('profile.menu.accountInformation')}}\n          </div> "),
        _createCommentVNode(" <div class=\"p-[16px] hover:bg-[#EBEDF0] border-b border-[#DCDFE6] text-[18px] text-[#606266] leading-[26px] opacity-40\"\n               :class=\"menuClass('/settings/accessTokens')\"\n          >\n            {{ $t('profile.menu.accessToken')}}\n          </div> "),
        _createCommentVNode(" access token "),
        ($setup.hasEmail)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: "/settings/access-token",
              class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/access-token')])
            }, _toDisplayString(_ctx.$t('profile.menu.gitToken')), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" starship api key "),
        ($setup.hasEmail)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: "/settings/starship-access-token",
              class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/starship-access-token')])
            }, _toDisplayString(_ctx.$t('profile.menu.starshipAccessToken')), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" sync access token "),
        ($setup.hasEmail)
          ? (_openBlock(), _createElementBlock("a", {
              key: 2,
              href: "/settings/sync-access-token",
              class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/sync-access-token')])
            }, _toDisplayString(_ctx.$t('profile.menu.syncAccessToken')), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" ssh key "),
        ($setup.hasEmail)
          ? (_openBlock(), _createElementBlock("a", {
              key: 3,
              href: "/settings/ssh-keys",
              class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/ssh-keys')])
            }, _toDisplayString(_ctx.$t('profile.menu.sshKey')), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true),
        ($setup.hasEmail)
          ? (_openBlock(), _createElementBlock("a", {
              key: 4,
              href: "/settings/billing",
              class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/billing')])
            }, _toDisplayString(_ctx.$t('profile.menu.billing')), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}