import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a811b757"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-[640px] m-auto flex flex-col items-center md:w-full md:p-5" }
const _hoisted_2 = { class: "text-[#303133] text-xl font-semibold mt-6 mb-3" }
const _hoisted_3 = { class: "text-[#606266] text-base font-medium md:text-center" }
const _hoisted_4 = { class: "mt-9 w-full" }
const _hoisted_5 = { class: "w-full flex md:flex-col gap-[16px] items-center" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "text-[#475467] mt-2 font-light" }
const _hoisted_8 = { class: "text-[#475467] font-light" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "text-[#475467] mt-2 font-light" }
const _hoisted_11 = { class: "text-[#475467] font-light" }
const _hoisted_12 = { class: "flex justify-end" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_Warning = _resolveComponent("Warning")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_SvgIcon, {
        name: "finetune_create",
        width: "36",
        height: "36"
      })
    ]),
    _createElementVNode("h3", _hoisted_2, _toDisplayString($setup.t('finetune.new.title')), 1 /* TEXT */),
    _createElementVNode("p", _hoisted_3, _toDisplayString($setup.t('finetune.new.desc')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_form, {
        ref: "dataFormRef",
        model: $setup.dataForm,
        rules: $setup.rules,
        class: "w-full flex flex-col gap-[14px]",
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_form_item, {
              class: "w-full",
              label: $setup.t('finetune.new.name'),
              prop: "deploy_name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.dataForm.deploy_name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dataForm.deploy_name) = $event)),
                  placeholder: 
                $setup.t('all.pleaseInput', { value: $setup.t('finetune.new.name') })
              ,
                  "input-style": "width: 100%"
                }, {
                  suffix: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      class: "item",
                      effect: "dark",
                      "raw-content": "",
                      content: `
                  <p>${$setup.t('finetune.new.tip')}</p>
                  <ul style='margin-left: 18px; list-style: disc; margin-top: 12px;'>
                    <li>${$setup.t('rule.lengthLimit', { min: 2, max: 64 })}</li>
                    <li>${$setup.t('rule.startWithLetter')}</li>
                    <li>${$setup.t('rule.endWithLetterOrNumber')}</li>
                    <li>${$setup.t('rule.onlyLetterNumberAndSpecialStr')}</li>
                    <li>${$setup.t('rule.specialStrNotTogether')}</li>
                  </ul>
                  `,
                      placement: "top"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Warning)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["content"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.t('finetune.new.modelId'),
              class: "w-full",
              prop: "model_id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_autocomplete, {
                  clearable: "",
                  modelValue: $setup.dataForm.model_id,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dataForm.model_id) = $event)),
                  "fetch-suggestions": $setup.fetchModels,
                  placeholder: 
                $setup.t('all.pleaseInput', { value: $setup.t('finetune.new.modelId') })
              ,
                  onChange: _ctx.updateRuntimeFramework
                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onChange"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]),
          _createVNode(_component_el_form_item, {
            label: $setup.t('finetune.new.cluster'),
            class: "w-full",
            prop: "cluster_id"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.dataForm.cluster_id,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.dataForm.cluster_id) = $event)),
                placeholder: 
              $setup.t('all.pleaseSelect', { value: $setup.t('finetune.new.cluster') })
            ,
                size: "large",
                style: {"width":"100%"},
                onChange: $setup.fetchResources
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.finetuneClusters, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.cluster_id,
                      label: item.region,
                      value: item.cluster_id
                    }, null, 8 /* PROPS */, ["label", "value"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "placeholder"]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('finetune.new.clusterDec1')), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('finetune.new.clusterDec2')), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_form_item, {
            label: $setup.t('finetune.new.resource'),
            class: "w-full",
            prop: "resource_id"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.dataForm.resource_id,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.dataForm.resource_id) = $event)),
                placeholder: 
              $setup.t('all.pleaseSelect', { value: $setup.t('finetune.new.resource') })
            ,
                size: "large",
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.finetuneResources, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.name,
                      label: item.name,
                      value: item.id,
                      disabled: !item.is_available
                    }, null, 8 /* PROPS */, ["label", "value", "disabled"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "placeholder"]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString($setup.t('finetune.new.resourceDec1')), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_11, _toDisplayString($setup.t('finetune.new.resourceDec2')), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_form_item, {
            label: $setup.t('finetune.new.framework'),
            class: "w-full",
            prop: "runtime_framework_id"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.dataForm.runtime_framework_id,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.dataForm.runtime_framework_id) = $event)),
                placeholder: 
              $setup.t('all.pleaseSelect', { value: $setup.t('finetune.new.framework') })
            ,
                size: "large",
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filterFrameworks, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.id,
                      label: item.frame_name,
                      value: item.id
                    }, null, 8 /* PROPS */, ["label", "value"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "placeholder"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  loading: $setup.loading,
                  class: "!text-center !h-9 !text-[16px] !text-white !bg-[#3250BD] !rounded-[8px] !border-[1px] !border-[#3250BD]",
                  onClick: $setup.handleSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.t('finetune.new.createFinetune')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["loading"])
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules"])
    ])
  ]))
}