import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "h-[50vh] py-[32px] flex flex-col items-center justify-center sm:px-[20px]" }
const _hoisted_2 = {
  key: 0,
  class: "text-[#6B7280] text-[16px]"
}
const _hoisted_3 = {
  key: 1,
  class: "text-[#6B7280] text-[16px]"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.appStatus === 'Stopped')
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('application_spaces.stoppedDesc')), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($props.appStatus === 'Sleeping')
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('application_spaces.sleepingDesc')), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.isStopped && $props.canWrite)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "border border-[#3250BD] bg-[#3250BD] rounded-[8px] shadow-xs px-[16px] py-[10px] text-white font-[500] text-[16px] leading-[24px] mt-[24px]",
          onClick: $setup.startSpace
        }, _toDisplayString(_ctx.$t('application_spaces.restart')), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.isSleeping)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          class: "border border-[#3250BD] bg-[#3250BD] rounded-[8px] shadow-xs px-[16px] py-[10px] text-white font-[500] text-[16px] leading-[24px] mt-[24px]",
          onClick: $setup.wakeupSpace
        }, _toDisplayString(_ctx.$t('application_spaces.wakeup')), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}