<template>
  <div class="max-w-[1280px] m-auto text-center px-[32px] md:px-[16px] relative">
    <div class="innerbox">
      <div>
        <p class="leftTopTit text-left">{{ $t('starShipIde.secTip1') }}</p>
        <p class="title text-left">{{ $t('starShipIde.secTit1') }}</p>
      </div>
      <div
        class="imgBox flex items-center justify-between gap-[32px] md:flex-wrap"
      >
        <div class="listInfoCont md:w-full">
          <div
            class="listInfoItem"
            :class="{ active: listActive == 1 }"
            @click="listClick(1)"
          >
            <p class="infoTit1">{{ $t('starShipIde.listItemTip1') }}</p>
            <p class="infoTit2">
              {{ $t('starShipIde.listItemTit1') }}
            </p>
          </div>
          <div
            class="listInfoItem"
            :class="{ active: listActive == 2 }"
            @click="listClick(2)"
          >
            <p class="infoTit1">{{ $t('starShipIde.listItemTip2') }}</p>
            <p class="infoTit2">
              {{ $t('starShipIde.listItemTit2') }}
            </p>
          </div>
          <div
            class="listInfoItem"
            :class="{ active: listActive == 3 }"
            @click="listClick(3)"
          >
            <p class="infoTit1">{{ $t('starShipIde.listItemTip3') }}</p>
            <p class="infoTit2">
              {{ $t('starShipIde.listItemTit3') }}
            </p>
          </div>
        </div>
        <div class="listImgCont flex-1 md:w-full">
          <img
            v-show="listActive == 1"
            src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/150ac867-6cc8-45f9-945e-dc1f5ad38f7a"
            alt=""
          />
          <img
            v-show="listActive == 2"
            src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/d160009e-5c5c-4bff-86ba-91cc04a89aa9"
            alt=""
          />
          <img
            v-show="listActive == 3"
            src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/16e41f75-4cfc-46e4-868f-721cbc47dc79"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <div class="max-w-[1280px] m-auto text-center px-[32px] md:px-[16px]">
    <div class="innerbox">
      <p class="leftTopTit">{{ $t('starShipIde.secTip2') }}</p>
      <p class="title my-[12px]">{{ $t('starShipIde.secTit2') }}</p>
      <p class="secTit">{{ $t('starShipIde.secDec1') }}</p>
      <p class="secTit">{{ $t('starShipIde.secDec2') }}</p>
      <div class="imgBox">
        <div class="flex gap-[36px] md:flex-wrap">
          <div class="imgInfoItemBox bgImgStyle flex-1 md:w-full md:flex-none">
            <p class="tit1">{{ $t('starShipIde.boxItem1Tip') }}</p>
            <p class="tit2">{{ $t('starShipIde.boxItem1Tit') }}</p>
            <img
              src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/e192c1e7-4beb-4970-972a-218c9bebe0a3"
              alt=""
            />
          </div>
          <div class="imgInfoItemBox bgImgStyle flex-1 md:w-full md:flex-none">
            <p class="tit1">{{ $t('starShipIde.boxItem2Tip') }}</p>
            <p class="tit2">{{ $t('starShipIde.boxItem2Tit') }}</p>
            <img
              src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/2b2a2691-ea8e-4478-a974-b2b65acc3e26"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="imgBox">
        <div class="flex gap-[36px] md:flex-wrap">
          <div class="imgInfoItemBox bgImgStyle flex-1 md:w-full md:flex-none">
            <p class="tit1">{{ $t('starShipIde.boxItem3Tip') }}</p>
            <p class="tit2">{{ $t('starShipIde.boxItem3Tit') }}</p>
            <img
              src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/7ef97f01-dbef-4754-a94b-a7d04cf56bf3"
              alt=""
            />
          </div>
          <div class="imgInfoItemBox bgImgStyle flex-1 md:w-full md:flex-none">
            <p class="tit1">{{ $t('starShipIde.boxItem4Tip') }}</p>
            <p class="tit2">{{ $t('starShipIde.boxItem4Tit') }}</p>
            <br />
            <img
              src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/e77d7b38-1790-4d8f-84e2-a24a54309e7e"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="max-w-[1280px] m-auto text-center px-[32px] md:px-[16px]">
    <div class="innerbox">
      <div class="px-[32px]">
        <p class="leftTopTit text-left">{{ $t('starShipIde.secTip3') }}</p>
        <p class="title my-[12px] text-left">{{ $t('starShipIde.secTit3') }}</p>
        <p class="secTit text-left w-[60%] md:w-full">
          {{ $t('starShipIde.secDec3') }}
        </p>
      </div>
      <div class="flex items-center justify-center my-[64px] md:my-[32px]">
        <div class="flex items-center justify-center gap-[24px]">
          <div
            class="btnItem"
            :class="{ active: btnActive == 1 }"
            @click="btnClick(1)"
          >
            {{ $t('starShipIde.btnText1') }}
          </div>
          <div
            class="btnItem"
            :class="{ active: btnActive == 2 }"
            @click="btnClick(2)"
          >
            {{ $t('starShipIde.btnText2') }}
          </div>
          <div
            class="btnItem"
            :class="{ active: btnActive == 3 }"
            @click="btnClick(3)"
          >
            Git diff
          </div>
        </div>
      </div>
      <div class="imgGroupCont">
        <img
          v-show="btnActive == 1"
          src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/68ef9c84-0131-4ae7-9a07-3108a85775d1"
          alt=""
        />
        <img
          v-show="btnActive == 2"
          src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/fc02c772-3ee2-4fbd-8180-9c7cb0c43516"
          alt=""
        />
        <img
          v-show="btnActive == 3"
          src="https://opencsg-portal-prd.oss-cn-beijing.aliyuncs.com/admin-photo/304d4b84-006f-4ef6-b9f2-e2b1faf94ac1"
          alt=""
        />
      </div>
    </div>
  </div>
  <div class="max-w-[1280px] m-auto text-center px-[32px] md:px-[16px]">
    <div class="innerbox">
      <p class="title mb-[12px]">{{ $t('starShipIde.secTip4') }}</p>
      <p class="secTit">{{ $t('starShipIde.secTit4') }}</p>
      <div
        class="imgBox flex items-stretch justify-between gap-[24px] md:flex-wrap"
      >
        <div
          class="lanItemCont bgImgStyle w-[61%] md:w-full grid grid-cols-11 gap-[16px] sm:hidden"
        >
          <div class="imgCont">
            <img
              src="images/ide/vue.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Java.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/ts.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/swift.png"
              alt=""
            />
          </div>
          <div class="introText col-span-3 text-center">
            <p class="numCont">100+</p>
            <p class="text-[#fff] text-[20px] text-nowrap">
              {{ $t('starShipIde.imgText') }}
            </p>
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Go.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Php.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Python.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Ruby.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/JavaScript.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/html.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/React.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/C.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/NodeJs.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Sass.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/C++.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Rust.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Kotlin.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/R.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Scala.png"
              alt=""
            />
          </div>
        </div>
        <div class="lanItemCont bgImgStyle hidden sm:flex lanItemContM w-full">
          <div class="imgCont">
            <img
              src="images/ide/vue.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Java.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/ts.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/swift.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Go.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Php.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Python.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Ruby.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/JavaScript.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/html.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/React.png"
              alt=""
            />
          </div>
          <div class="introText col-span-3 text-center">
            <p class="numCont">100+</p>
            <p class="text-[#fff] text-[20px] text-nowrap">
              {{ $t('starShipIde.imgText') }}
            </p>
          </div>
          <div class="imgCont">
            <img
              src="images/ide/C.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/NodeJs.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Sass.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/C++.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Rust.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Kotlin.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/R.png"
              alt=""
            />
          </div>
          <div class="imgCont">
            <img
              src="images/ide/Scala.png"
              alt=""
            />
          </div>
        </div>
        <div
          class="ideItemCont flex-1 md:w-full"
          @click="
            toSecPage(
              'https://marketplace.visualstudio.com/items?itemName=OpenCSG.codesouler'
            )
          "
        >
          <div class="ideItem bgImgStyle">
            <div class="nameCont">
              <p class="vscodeName">VSCode</p>
              <p class="nameInfo">Extensions</p>
            </div>
            <img
              src="images/ide/vscode.png"
              alt=""
            />
          </div>
          <div
            class="ideItem bgImgStyle"
            @click="
              toSecPage('https://plugins.jetbrains.com/plugin/24737-codesouler')
            "
          >
            <div class="nameCont">
              <p class="jtbName">JetBrains</p>
              <p class="nameInfo">Plugins</p>
            </div>
            <img
              src="images/ide/jet.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  const btnActive = ref(1)
  const listActive = ref(1)
  function btnClick(index) {
    btnActive.value = index
  }
  function listClick(index) {
    listActive.value = index
  }
  const toSecPage = (url) => {
    window.open(url, '_blank')
  }
</script>
<style lang="less" scoped>
  .innerbox {
    padding: 96px 0px;
    @media (max-width: 768px) {
      padding: 48px 0px;
    }
    .leftTopTit {
      color: #eaecf0;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .title {
      font-size: 36px;
      font-weight: 500;
      color: #fff;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    .secTit {
      color: #667085;
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .imgBox {
    margin-top: 80px;
    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }
  .imgInfoItemBox {
    // flex: 1;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: rgba(255, 255, 255, 0.04);
    padding: var(--spacing-3xl, 24px);
    text-align: left;
    .tit1 {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 8px;
    }
    .tit2 {
      color: var(--Base-White, #fff);
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 20px;
    }
    img {
      width: 100%;
    }
    @media (max-width: 768px) {
      padding: var(--spacing-2xl, 16px);
    }
  }
  .btnItem {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 6px;
    color: var(--Gray-500, #667085);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: all 0.3s ease-in-out;
    &:hover,
    &.active {
      background: #f9fafb;
      color: var(--Gray-700, #344054);
    }
  }
  .imgGroupCont {
    border-radius: var(--spacing-4xl, 32px);
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: rgba(255, 255, 255, 0.04);
    padding: var(--spacing-3xl, 24px);
    img {
      width: 100%;
    }
  }
  .ideItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--spacing-xl, 16px);
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 32px;
    text-align: left;
    flex-grow: 1;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #344054;
    }
    img {
      width: 38px;
    }
    .vscodeName {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      background: linear-gradient(130deg, #1791d2 5.87%, #1173c5 93.2%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .jtbName {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      background: linear-gradient(
        45deg,
        #ff9419 1.66%,
        #ff021d 73.63%,
        #e600ff 96.55%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .nameInfo {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .lanItemCont {
    border-radius: var(--spacing-xl, 16px);
    border: 1px solid rgba(255, 255, 255, 0.08);
    background-color: rgba(255, 255, 255, 0.05);
    padding: 24px;
    grid-auto-rows: auto !important;
    .imgCont {
      align-self: center;
      display: block;
    }
    img {
      width: 100%;
      border-radius: 9.6px;
    }
  }
  .listImgCont {
    border-radius: 23.399px;
    border: 0.975px solid rgba(255, 255, 255, 0.08);
    background: rgba(255, 255, 255, 0.04);
    padding: 15.6px;
    img {
      border-radius: 7.8px;
      width: 100%;
    }
  }
  .listInfoCont {
    width: 27.6%;
    text-align: left;
    .listInfoItem {
      cursor: pointer;
      border-left: var(--spacing-xs, 4px) solid var(--Gray-700, #344054);
      padding: 16px 0 16px 24px;
      transition: all 0.5s ease-in-out;
      .infoTit1 {
        color: var(--Gray-400, #98a2b3);
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 8px;
      }
      .infoTit2 {
        width: 80%;
        color: var(--Gray-600, #475467);
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
      &:hover {
        .infoTit1 {
          color: #d0d5dd;
        }
        .infoTit2 {
          color: #98a2b3;
        }
      }
      &.active {
        border-left: var(--spacing-xs, 4px) solid var(--Base-White, #fff);
        .infoTit1 {
          color: #fff;
        }
        .infoTit2 {
          color: #eaecf0;
        }
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .numCont {
    background: var(
      --Gradient-Linear-Color-66,
      linear-gradient(45deg, #ff7a00 0%, #ffd439 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: D-DIN;
    font-size: 48px;
    font-weight: 700;
  }
  .ideItemCont {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .bgImgStyle {
    background-image: url('../images/bgImg.png');
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: left top;
  }
  .lanItemContM {
    padding: 24px 16px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .imgCont {
      flex: 0 0 calc(20% - 8px);
    }
    .numCont {
      flex: 0 0 calc(60% - 8px);
    }
  }
</style>
