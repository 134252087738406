import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-[#F9FAFB] flex flex-col justify-center py-[96px] md:py-[64px] text-center" }
const _hoisted_2 = { class: "max-w-[1280px] m-auto md:px-[16px]" }
const _hoisted_3 = { class: "text-[16px] leading-[24px] md:text-[14px] md:leading-[20px] text-[#223B99] mb-[8px]" }
const _hoisted_4 = { class: "text-[48px] leading-[60px] md:text-[30px] md:leading-[38px] text-[#101828] mb-[20px]" }
const _hoisted_5 = { class: "text-[20px] leading-[30px] md:text-[18px] md:leading-[28px] text-[#475467] mb-[64px]" }
const _hoisted_6 = { class: "grid grid-cols-3 md:grid-cols-1 gap-[32px] justify-items-center" }
const _hoisted_7 = { class: "flex flex-col items-center" }
const _hoisted_8 = { class: "mb-[16px] px-[12px] py-[12px] border border-[ #EAECF0] bg-[#FFF] rounded-[10px]" }
const _hoisted_9 = { class: "text-[20px] mb-[8px] md:text-[18px] md:leading-[28px] text-[#101828] leading-[30px]" }
const _hoisted_10 = { class: "text-[16px] text-[#475467] leading-[24px] font-light" }
const _hoisted_11 = { class: "flex flex-col items-center" }
const _hoisted_12 = { class: "mb-[16px] px-[12px] py-[12px] border border-[ #EAECF0] bg-[#FFF] rounded-[10px]" }
const _hoisted_13 = { class: "text-[20px] mb-[8px] md:text-[18px] md:leading-[28px] text-[#101828] leading-[30px]" }
const _hoisted_14 = { class: "text-[16px] text-[#475467] leading-[24px] font-light" }
const _hoisted_15 = { class: "flex flex-col items-center" }
const _hoisted_16 = { class: "mb-[16px] px-[12px] py-[12px] border border-[ #EAECF0] bg-[#FFF] rounded-[10px]" }
const _hoisted_17 = { class: "text-[20px] mb-[8px] md:text-[18px] md:leading-[28px] text-[#101828] leading-[30px]" }
const _hoisted_18 = { class: "text-[16px] text-[#475467] leading-[24px] font-light" }

export function render(_ctx, _cache) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('landingPage.feature.subtitle')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('landingPage.feature.title')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('landingPage.feature.desc')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_SvgIcon, {
              name: "feature_integration",
              width: "24",
              height: "24"
            })
          ]),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('landingPage.feature.item_1_title')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('landingPage.feature.item_1_desc')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_SvgIcon, {
              name: "feature_governance",
              width: "24",
              height: "24"
            })
          ]),
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('landingPage.feature.item_2_title')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('landingPage.feature.item_2_desc')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_SvgIcon, {
              name: "feature_empowerment",
              width: "24",
              height: "24"
            })
          ]),
          _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('landingPage.feature.item_3_title')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('landingPage.feature.item_3_desc')), 1 /* TEXT */)
        ])
      ])
    ])
  ]))
}