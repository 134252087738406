export const solution = {
  "banner": {
    "headline": "Large Language Models redefine new paradigm for software development and engineering delivery",
    "solution": "OpenCSG Solution"
  },
  "knowledge": {
    "headline": "OpenCSG EKB AI Intelligent Enterprise Knowledge Base",
    "desc": "EKB AI structures documents and transforms them into digital assets, achieving digital storage and management of information, and solidifying the foundation for digital transformation of enterprise assets.",
    "demoVideo": "Product demonstration",
    "demo": "Get Started",
    "remarks": "Please {contact} to get trial access as registration is not yet available.",
  },
  "starAIO": {
    "headline": "OpenCSG StarAIO",
    "desc": "Excellent open source tools and platforms are available for developers to help enterprises customize private Large Language Models.",
    "part1": "Continuously iterative, change-at-will base LLMs",
    "part1-": "LLMs can be continuously updated and upgraded to adapt to different scenarios and business needs.",
    "part2": "Easy to use LLM application development with an open source framework",
    "part2-": "An open source framework is adopted to facilitate developers to build and customize LLM applications.",
    "part3": "On-prem deployed, secure and compliant",
    "part3-": "Deployment of LLM services within owned data centers for data privacy and security compliance.",
    "part4": "Container deployment, seamless migration",
    "part4-": "Support the development of plugins that comply with standard API interface specifications, expand product functionality and application scenarios.",
    "subpart1": "Unlocking unlimited creativity and achieving personalized AI experience",
    "subpart1-": "The LLM AIO offers continuous iteration and change-at-will LLM services, allowing you to unleash your creativity and customize your own unique AI experience. Whether it is for chatting, Chinese writing or other scenarios, our products will open the door to endless possibilities.",
    "subpart2": "Data security guarantee, a secure choice for mastering sensitive information",
    "subpart2-": "In the era of advocating data security, we are well aware of your importance on privacy protection. The big model all-in-one machine supports localized deployment, ensuring that your big model and data always run in a secure and controllable environment. Choose our product to ensure the most reliable protection of your sensitive information.",
    "subpart3": "Easy deployment, efficient migration to cloud services or other hardware",
    "subpart3-": "With containerized deployment technology, LLM AIO can be easily migrated to other hardware devices or cloud service platforms. Whether you need elastic scalability or access to cloud computing resources, we provide you with simple and fast solutions to ensure that your LLM is always at its best.",
    "subpart4": "Open source framework to assist developers in building custom applications",
    "subpart4-": "Based on an open source framework, LLM AIO provides developers with excellent tools and platforms to help them build and customize LLM applications. Whether you are looking for an innovative breakthrough point or want to provide personalized services, we will work with you to achieve the goals.",
    "subpart5": "Meeting the needs of localization and leading the digital transformation of the industry",
    "subpart5-": "LLM AIO is compatible with localized hardware and adapted to the Information Technology Application Innovation system, providing localized Al solutions for traditional enterprises such as finance, government and operators. Through the comprehensive adaptation with the Information Technology Application Innovation ecosystem, we are committed to promoting the digital transformation of the industry, so that the computing power of the domestic CPU and the compatibility of the LLMs are perfectly combined."
  },
  "AIImg": {
    "headline": "OpenCSG Imagen AI Intelligent Image Assistant",
    "desc": "OpenCSG Imagen AI Image Assistant is a visual toolset based on the Stable Diffusion 2.1 open source model, dedicated to augmenting human intelligence, aiming to provide creators with better tools and amplify creativity and productivity. OpenCSG Imagen AI Image Assistant adapts to professional design workflows, providing a wide range of features.",
    "demo": "Fast Try",
    "part1": "Text-to-image plotting mode",
    "part1-": "No specialized drawing skills are required, and users can generate high-quality drawings in seconds with a simple description.",
    "part2": "Image-to-image plotting mode",
    "part2-": "Choose from a wide range of drawing styles and artist references to enhance design quality and drawing efficiency by drawing on different styles of work.",
    "part3": "Generate images based on text descriptions",
    "part3-": "Generate corresponding images based on user provided text descriptions to expand creative possibilities.",
    "part4": "Text-based guided modification of images",
    "part4-": "Users can use text guidance to modify existing images to meet their creative needs.",
    "part5": "Stylistic migration of artists' works",
    "part5-": "Assist creators explore new ways of expression by studying the work of different artists or styles and blending them to generate images with a specific artistic style.",
    "part6": "Image super-resolution",
    "part6-": "OpenCSG Image AI Image Assistant improves image resolution for clearer and more detailed images."
  },
  "programming": {
    "headline": "OpenCSG StarCode AI Intelligent Programming Assistant",
    "desc": "StarCode AI is a versatile intelligent programming assistant that helps developers improve their work efficiency through innovative features based on large model technology. It integrates the advantages of other auxiliary programming assistants and provides developers with more powerful features.",
    "demo": "Fast Try",
    "part1": "Automatic code generation and completion",
    "part1-": "Supports automatic code generation based on natural language annotations, as well as generating subsequent code based on existing code, making the coding process faster and more accurate. OpenCSG StarCode AI further provides completion functionality for the current line or generating subsequent lines of code, helping developers easily complete programming tasks.",
    "part2": "Automatically generate unit tests",
    "part2-": "Supports generating unit tests and interpreting code related to unit tests based on common unit testing frameworks. StarCode AI further provides completion functionality for the current or subsequent lines of code, helping developers easily complete programming tasks.",
    "part3": "Q&A on research and development",
    "part3-": "Supports intelligent question answering function, allowing developers to directly ask technical questions to assistants without leaving the integrated development environment (IDE) to search for answers on search engines. This seamless integration experience allows developers to focus more on solving problems and improve work efficiency. StarCode AI also provides completion function for the current line or generating subsequent lines of code, helping developers easily complete programming tasks.",
    "part4": "Code translation",
    "part4-": "Not only does it support mutual translation between multiple mainstream programming languages, but it also draws on the features of CoPilot to achieve more accurate and high-quality code translation based on artificial intelligence models. StarCode AI further provides completion functionality for the current line or generating subsequent line codes, helping developers easily complete programming tasks."
  },
}
