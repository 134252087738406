import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c7bf049"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex items-center justify-between mb-1 w-[399px] lg:w-full" }
const _hoisted_3 = { class: "flex gap-1" }
const _hoisted_4 = { class: "flex flex-nowrap overflow-hidden text-ellipsis items-center gap-[8px] text-xs text-[#667085]" }
const _hoisted_5 = {
  key: 0,
  class: "max-w-[80px] xl:max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "overflow-hidden text-ellipsis whitespace-nowrap" }
const _hoisted_8 = { class: "whitespace-nowrap" }
const _hoisted_9 = { class: "whitespace-nowrap" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createElementBlock("a", {
    href: $setup.detailLink,
    class: _normalizeClass([`${$props.repoType}-card`, "focus:outline focus:outline-4 focus:outline-[#EAECF0] hover:shadow-md p-4 mlg:w-full border border-[#EAECF0] rounded-lg flex-grow xl:basis-full min-w-[250px] xl:max-w-full"]),
    style: _normalizeStyle($props.isCollection ? 'width:100%' : '')
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([`${$props.repoType}-path`, "text-md text-[#344054] font-normal text-ellipsis overflow-hidden whitespace-nowrap"])
      }, _toDisplayString($setup.getComputed.path), 3 /* TEXT, CLASS */),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_tooltip, {
          effect: "light",
          content: _ctx.$t('repo.source.needSync'),
          placement: "top"
        }, {
          default: _withCtx(() => [
            (!!$setup.needSyncIcon)
              ? (_openBlock(), _createBlock(_component_SvgIcon, {
                  key: 0,
                  name: $setup.needSyncIcon
                }, null, 8 /* PROPS */, ["name"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode(_component_el_tooltip, {
          effect: "light",
          content: $setup.syncTooltip,
          placement: "top"
        }, {
          default: _withCtx(() => [
            (!!$setup.sourceIcon && !$setup.needSyncIcon)
              ? (_openBlock(), _createBlock(_component_SvgIcon, {
                  key: 0,
                  name: $setup.sourceIcon
                }, null, 8 /* PROPS */, ["name"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"])
      ])
    ]),
    ($setup.getComputed.showDescription)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(["w-[390px] lg:w-[370px] mlg:w-full h-[35px] leading-[18px] mb-1 text-[#667085] text-sm overflow-hidden text-ellipsis line-clamp-2 text-left", $props.isCollection ? 'hidden' : ''])
        }, _toDisplayString($props.repo.description), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_4, [
      ($setup.getComputed.taskTag)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($setup.getComputed.taskTag), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($setup.getComputed.taskTag)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
            _createVNode(_component_SvgIcon, { name: "vertical_divider" })
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('all.lastTime')) + "：" + _toDisplayString($props.repo.updated_at.substring(0, 10)), 1 /* TEXT */),
      _createElementVNode("span", null, [
        _createVNode(_component_SvgIcon, { name: "vertical_divider" })
      ]),
      _createElementVNode("span", _hoisted_8, _toDisplayString($setup.getComputed.visibility), 1 /* TEXT */),
      _createElementVNode("span", null, [
        _createVNode(_component_SvgIcon, { name: "vertical_divider" })
      ]),
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('all.downloadCount')) + "：" + _toDisplayString($props.repo.downloads), 1 /* TEXT */)
    ])
  ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1))
}