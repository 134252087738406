import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode($setup["GradioInitializeGuide"], {
      "http-clone-url": $props.httpCloneUrl,
      "ssh-clone-url": $props.sshCloneUrl,
      "user-name": $props.userName,
      "user-token": $setup.accessToken,
      sdk: $props.sdk
    }, null, 8 /* PROPS */, ["http-clone-url", "ssh-clone-url", "user-name", "user-token", "sdk"]), [
      [_vShow, $props.sdk == 'gradio']
    ]),
    _withDirectives(_createVNode($setup["StreamlitInitializeGuide"], {
      "http-clone-url": $props.httpCloneUrl,
      "ssh-clone-url": $props.sshCloneUrl,
      "user-name": $props.userName,
      "user-token": $setup.accessToken,
      sdk: $props.sdk
    }, null, 8 /* PROPS */, ["http-clone-url", "ssh-clone-url", "user-name", "user-token", "sdk"]), [
      [_vShow, $props.sdk == 'streamlit']
    ])
  ], 64 /* STABLE_FRAGMENT */))
}