import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SolutionBanner = _resolveComponent("SolutionBanner")
  const _component_SolutionStarAIO = _resolveComponent("SolutionStarAIO")
  const _component_SolutionAIImg = _resolveComponent("SolutionAIImg")
  const _component_SolutionProgramming = _resolveComponent("SolutionProgramming")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SolutionBanner),
    _createVNode(_component_SolutionStarAIO),
    _createVNode(_component_SolutionAIImg),
    _createVNode(_component_SolutionProgramming)
  ]))
}