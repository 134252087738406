import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-[14px] text-[#344054] cursor-pointer flex items-center" }
const _hoisted_2 = { class: "px-[8px] py-[6px] bg-[#F0F3FF] border border-r-0 border-[#D0D5DD] rounded-l-[6px]" }
const _hoisted_3 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: '/images/tags/' + $props.tag.name + '.svg',
        class: "w-[14px] h-[14px]",
        alt: "",
        onerror: "this.style.display='none'"
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("p", {
      class: _normalizeClass(["h-[28px] pl-[4px] pr-[8px] py-[3px] border border-l-0 border-[#D0D5DD] rounded-r-[6px]", { 'bg-[#4D6AD6]': $props.active, 'text-white': $props.active, 'border-[#4D6AD6]': $props.active }]),
      onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('handleTagClick', $props.tag)))
    }, _toDisplayString($setup.tagName), 3 /* TEXT, CLASS */)
  ]))
}