import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-[16px] border-t border-[#EBEEF5]" }
const _hoisted_2 = { class: "flex items-center gap-[8px]" }
const _hoisted_3 = { class: "font-[500] text-[#344054] text-[16px] leading-[24px]" }
const _hoisted_4 = { class: "text-[#344054]" }
const _hoisted_5 = { class: "text-[#667085]" }
const _hoisted_6 = { class: "mt-[16px] flex gap-[12px]" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "rounded-md border border-gray-300 bg-white py-0.5 pr-2.5 pl-1.5" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _createVNode(_component_SvgIcon, {
        name: "spaces",
        width: "18px",
        height: "18px"
      }),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('spaces.using')), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_4, _toDisplayString($props.namespacePath), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_5, _toDisplayString($props.spaces.length), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.spaces, (space) => {
        return (_openBlock(), _createElementBlock("a", {
          href: `/spaces/${space.path}`
        }, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(space.path), 1 /* TEXT */)
        ], 8 /* PROPS */, _hoisted_7))
      }), 256 /* UNKEYED_FRAGMENT */))
    ])
  ]))
}