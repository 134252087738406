import request from './request'

/** 具身智能设备 开始 */
// 创建具身智能设备
export function eaiDeviceCreate(data) {
  return request({
    url: `/internal_api/admin/eai_devices/`,
    method: 'post',
    data: data
  })
}

// 更新具身智能设备
export function eaiDeviceUpdate(data) {
  return request({
    url: `/internal_api/admin/eai_devices/${data.id}`,
    method: 'put',
    data: data
  })
}

// 查看具身智能设备
export function eaiDeviceShow(id) {
  return request({
    url: `/internal_api/admin/eai_devices/${id}`,
    method: 'get',
  })
}

// 搜索具身智能设备
export function eaiDeviceList(query) {
  return request({
    url: `/internal_api/admin/eai_devices`,
    method: 'get',
    params: query
  })
}

// 查看具身智能设备
export function eaiDeviceDetail(id) {
  return request({
    url: `/internal_api/admin/eai_devices/${id}`,
    method: 'get',
  })
}

/** 具身智能设备 结束 */

/** 具身智能文章 开始 */
// 创建具身智能文章
export function eaiArticleCreate(data) {
  return request({
    url: `/internal_api/admin/eai_articles/`,
    method: 'post',
    data: data
  })
}

// 更新具身智能文章
export function eaiArticleUpdate(data) {
  return request({
    url: `/internal_api/admin/eai_articles/${data.id}`,
    method: 'put',
    data: data
  })
}

// 查看具身智能文章
export function eaiArticleShow(id) {
  return request({
    url: `/internal_api/admin/eai_articles/${id}`,
    method: 'get',
  })
}

// 搜索具身智能文章
export function eaiArticleList(query) {
  return request({
    url: `/internal_api/admin/eai_articles`,
    method: 'get',
    params: query
  })
}

// 查看具身智能文章
export function eaiArticleDetail(id) {
  return request({
    url: `/internal_api/admin/eai_articles/${id}`,
    method: 'get',
  })
}
/** 具身智能文章 结束 */