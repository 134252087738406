import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "my-[16px]" }
const _hoisted_2 = {
  class: "h-[80vh] border mt-[8px] overflow-scroll p-[8px]",
  ref: "instanceLogDiv"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_select, {
      modelValue: $setup.currentInstance,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.currentInstance) = $event)),
      placeholder: "Select",
      style: {"width":"240px"},
      onChange: $setup.refreshInstanceLogs
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.instances, (instance) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: instance.name,
            label: instance.name,
            value: instance.name
          }, null, 8 /* PROPS */, ["label", "value"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */)
  ]))
}