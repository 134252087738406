<template>
  <div class="w-full overflow-hidden">
    <StarShipBanner />
    <StarShipSection />
  </div>
</template>

<script setup>
  import StarShipBanner from './StarShipBanner.vue'
  import StarShipSection from './StarShipSection.vue'
  import useWxShare from '../hooks/useWxShare'
  import { onMounted } from "vue"

  onMounted(() => {
    useWxShare({
      title: 'Starship CodeSouler',
      desc: '助力研发流程，您的智能Coding伴侣',
      link: window.location.href
    })
  })
</script>
