import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EkbBanner = _resolveComponent("EkbBanner")
  const _component_EkbAsk = _resolveComponent("EkbAsk")
  const _component_EkbMore = _resolveComponent("EkbMore")
  const _component_EkbAdvantages = _resolveComponent("EkbAdvantages")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_EkbBanner),
    _createVNode(_component_EkbAsk),
    _createVNode(_component_EkbMore),
    _createVNode(_component_EkbAdvantages)
  ]))
}