import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mb-[96px] md:mb-[64px] w-full bg-white overflow-x-auto" }
const _hoisted_2 = { class: "rounded-[12px] border md:border-0 border-[#EAECF0] w-[1280px] m-auto" }
const _hoisted_3 = { class: "flex md:flex-col justify-between py-[24px] px-[20px] md:py-0" }
const _hoisted_4 = { class: "flex items-center font-medium text-[18px] md:mb-[16px]" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "text-[#FFFFFF] font-medium text-[16px]" }
const _hoisted_8 = { class: "text-[#FFFFFF] font-medium text-[16px]" }
const _hoisted_9 = { class: "flex items-center text-[#475467] text-[12px] bg-[#F9FAFB] border border-[#EAECF0]" }
const _hoisted_10 = { class: "w-36 py-[12px] px-[24px] responsive-div" }
const _hoisted_11 = { class: "w-48 py-[12px] px-[24px] responsive-div" }
const _hoisted_12 = { class: "w-80 py-[12px] px-[24px] responsive-div" }
const _hoisted_13 = { class: "w-24 py-[12px] px-[24px] responsive-div" }
const _hoisted_14 = { class: "w-20 py-[12px] px-[24px] responsive-div" }
const _hoisted_15 = { class: "w-28 py-[12px] px-[24px] responsive-div" }
const _hoisted_16 = { class: "w-44 py-[12px] px-[24px] responsive-div" }
const _hoisted_17 = { class: "w-40 py-[12px] px-[24px] responsive-div" }
const _hoisted_18 = { class: "flex items-center text-[#101828] text-[14px] border-b border-[#EAECF0] last-of-type:border-0" }
const _hoisted_19 = { class: "w-36 flex items-center py-[12px] pl-[24px] responsive-div" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "ml-[12px]" }
const _hoisted_22 = { class: "w-48 py-[12px] px-[24px] whitespace-pre-wrap responsive-div" }
const _hoisted_23 = { class: "w-80 py-[12px] px-[24px] whitespace-pre-wrap whitespace-nowrap" }
const _hoisted_24 = { class: "w-24 py-[12px] px-[24px] whitespace-pre-wrap responsive-div" }
const _hoisted_25 = { class: "w-20 py-[12px] px-[24px] whitespace-pre-wrap responsive-div" }
const _hoisted_26 = { class: "w-28 py-[12px] px-[24px] whitespace-pre-wrap responsive-div" }
const _hoisted_27 = { class: "w-44 py-[12px] px-[24px] whitespace-pre-wrap responsive-div" }
const _hoisted_28 = { class: "w-40 py-[12px] px-[24px] whitespace-pre-wrap responsive-div" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('computing.price.gpu')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", {
            href: $props.starcloudUrl,
            target: "_blank",
            class: "bg-[#3250BD] mr-[16px] flex items-center justify-center py-[10px] px-[12px] md:mb-[16px] rounded-[8px] cursor-pointer"
          }, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('navbar.source')), 1 /* TEXT */)
          ], 8 /* PROPS */, _hoisted_6),
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = $event => (
              $options.toNextPage(
                'https://opencsg.com/lead_forms/form/5P4op4FQHwh5_%E7%AE%97%E5%8A%9B%E9%9C%80%E6%B1%82',
                'connect_compute'
              )
            )),
            class: "bg-[#3250BD] flex items-center justify-cente py-[10px] px-[12px] md:mb-[16px] rounded-[8px] cursor-pointer"
          }, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('computing.price.consult')), 1 /* TEXT */)
          ])
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('computing.price.brand')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('computing.price.specifications')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('computing.price.power')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('computing.price.config')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('computing.price.inventory')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('computing.price.service')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('computing.price.type')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('computing.price.price')), 1 /* TEXT */)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.specificationsList, (specification) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("img", {
                src: specification.logo[0],
                class: _normalizeClass(specification.logo[1]),
                alt: "nvidia"
              }, null, 10 /* CLASS, PROPS */, _hoisted_20),
              _createElementVNode("p", _hoisted_21, _toDisplayString(specification.brand), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_22, _toDisplayString(specification.specification), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("p", null, _toDisplayString(specification.power), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_24, _toDisplayString(specification.config), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_25, _toDisplayString(specification.number), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_26, _toDisplayString(specification.status), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_27, _toDisplayString(specification.type), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_28, _toDisplayString(specification.price), 1 /* TEXT */)
          ]))
        }), 256 /* UNKEYED_FRAGMENT */))
      ])
    ])
  ]))
}