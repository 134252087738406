import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d0c360f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col justify-center py-[96px] md:py-[64px] text-center" }
const _hoisted_2 = { class: "max-w-[1280px] m-auto md:px-[16px]" }
const _hoisted_3 = { class: "text-[16px] leading-[24px] md:text-[14px] md:leading-[20px] text-[#223B99] mb-[8px]" }
const _hoisted_4 = { class: "text-[36px] leading-[44px] md:text-[30px] md:leading-[38px] text-[#101828] mb-[20px]" }
const _hoisted_5 = { class: "text-[20px] leading-[30px] md:text-[18px] md:leading-[28px] text-[#475467] mb-[64px] md:mb-[48px]" }
const _hoisted_6 = { class: "grid grid-cols-3 mb-[64px] md:grid-cols-1 gap-[32px] text-left" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "mb-[20px] rounded-[16px]" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "text-[14px] text-[#223B99] leading-[20px] mb-[8px]" }
const _hoisted_12 = ["title"]
const _hoisted_13 = { class: "text-[16px] text-[#475467] leading-[24px] font-light mb-[24px]" }
const _hoisted_14 = { class: "flex gap-[8px] text-[14px] leading-[20px]" }
const _hoisted_15 = {
  href: "https://blog.opencsg.com/",
  target: "_blank",
  class: "inline md:block px-[22px] py-[16px] rounded-[8px] text-[#FFF] bg-[#32508D] border border-[32508D] cursor-pointer"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('landingPage.blog.subtitle')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('landingPage.blog.title')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('landingPage.blog.desc')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.latestNews, (blog) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("a", {
              href: blog.url
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: blog.image,
                  alt: "",
                  class: "w-full"
                }, null, 8 /* PROPS */, _hoisted_10)
              ]),
              _createElementVNode("div", _hoisted_11, _toDisplayString(blog.date), 1 /* TEXT */),
              _createElementVNode("div", {
                class: "overflow-hidden whitespace-nowrap text-ellipsis text-[24px] mb-[8px] md:text-[20px] md:leading-[30px] text-[#101828] leading-[32px]",
                title: blog.title
              }, _toDisplayString(blog.title), 9 /* TEXT, PROPS */, _hoisted_12),
              _createElementVNode("div", _hoisted_13, _toDisplayString(blog.desc) + " ... ", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(blog.tags, (tag) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["px-[10px] py-[2px] rounded-[16px] border", $setup.tagColor(tag)])
                  }, _toDisplayString(tag), 3 /* TEXT, CLASS */))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ], 8 /* PROPS */, _hoisted_8)
          ]))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]),
      _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.$t('landingPage.blog.view_more')), 1 /* TEXT */)
    ])
  ]))
}