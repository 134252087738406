import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-[96px] md:py-[64px] md:mb-[32px] text-center w-full banner bg-white" }
const _hoisted_2 = { class: "flex justify-center items-center flex-col max-w-[1280px] m-auto xl:px-[20px]" }
const _hoisted_3 = { class: "max-w-[850px] m-auto md:mb-[12px] font-medium text-[48px] md:text-[36px] md:text-[48px] md:leading-[50px] text-[#101828] leading-[90px]" }
const _hoisted_4 = { class: "mb-[48px] md:mb-[24px] text-[20px] md:text-[18px] font-light text-[#475467] leading-[30px]" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('computing.banner.headline')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('computing.banner.desc')), 1 /* TEXT */),
      _createCommentVNode(" <div class=\"flex justify-center items-center bg-[#F9FAFB] p-[6px] gap-[4px] rounded-[12px] border border-[#EAECF0] text-[#344054]\">\n        <div class=\"bg-white py-[8px] px-[12px] md:px-[38px] rounded-[6px] border cursor-pointer\">{{ $t('computing.banner.option1') }}</div>\n        <div class=\"flex justify-center items-center gap-[8px] py-[8px] px-[12px] text-[#667085]\"><p class=\"cursor-pointer\">{{ $t('computing.banner.option2') }}</p>\n          <div class=\"py-[2px] px-[10px] text-[14px] rounded-[16px] border border-[#EAECF0] text-[#344054]\">{{ $t('computing.banner.option3') }}</div>\n        </div>\n      </div> ")
    ])
  ]))
}