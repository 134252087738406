import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eb4df4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full bg-[#FCFCFD] pt-9 pb-[60px] xl:px-10 md:px-0 md:pb-6 md:h-auto" }
const _hoisted_2 = { class: "mx-auto max-w-[1280px]" }
const _hoisted_3 = { class: "mx-auto max-w-[1280px] mt-[-40px] xl:px-10 md:px-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["RepoHeader"], {
          private: $setup.endpoint.private,
          name: $setup.endpoint.deploy_name,
          path: $setup.endpoint.model_id,
          appStatus: $setup.appStatus,
          "space-resource": $setup.endpoint.hardware,
          avatar: $setup.avatar,
          "owner-url": $setup.ownerUrl,
          "repo-type": "endpoint",
          repoId: $setup.endpoint.repository_id
        }, null, 8 /* PROPS */, ["private", "name", "path", "appStatus", "space-resource", "avatar", "owner-url", "repoId"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["RepoTabs"], {
        "repo-detail": $setup.endpoint,
        appStatus: $setup.appStatus,
        appEndpoint: $setup.appEndpoint,
        "current-path": $props.currentPath,
        "default-tab": $props.defaultTab,
        actionName: $props.actionName,
        settingsVisibility: $setup.canManage,
        "can-write": $setup.canWrite,
        "repo-type": "endpoint",
        hardware: $setup.endpoint.hardware,
        modelId: $setup.endpoint.model_id,
        private: $setup.endpoint.private,
        endpointReplica: $setup.endpoint.actual_replica,
        endpointName: $setup.endpoint.deploy_name,
        endpointId: $setup.endpoint.deploy_id,
        userName: $props.namespace,
        replicaList: $setup.replicaList,
        path: `${$props.namespace}/${$props.modelName}`
      }, null, 8 /* PROPS */, ["repo-detail", "appStatus", "appEndpoint", "current-path", "default-tab", "actionName", "settingsVisibility", "can-write", "hardware", "modelId", "private", "endpointReplica", "endpointName", "endpointId", "userName", "replicaList", "path"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}