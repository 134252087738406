import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full bg-white" }
const _hoisted_2 = { class: "max-w-[1280px] m-auto py-[96px] px-[20px]" }
const _hoisted_3 = { class: "text-[#223B99] text-[16px] leading-[24px] mb-[10px]" }
const _hoisted_4 = { class: "text-[36px] leading-[44px] text-[#101828] mb-[20px]" }
const _hoisted_5 = { class: "max-w-[768px] text-[20px] text-[#475467] leading-[30px] font-light mb-[64px]" }
const _hoisted_6 = { class: "flex gap-[24px] mlg:flex-col" }
const _hoisted_7 = { class: "flex flex-col gap-[8px]" }
const _hoisted_8 = { class: "h-[48x] w-[48px] bg-[#3250BD] px-[12px] py-[12px] rounded-[10px] mb-[64px] md:mb-[20px]" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "text-[#101828] text-[20px] leading-[30px]" }
const _hoisted_11 = { class: "text-[#101828] text-[16px] leading-[24px] font-light" }
const _hoisted_12 = {
  href: "https://ekb.opencsg.com/",
  target: "_blank",
  class: "text-[#223B99] text-[16px] leading-[24px] cursor-pointer"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("ekb.adv")), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("ekb.advTitle")), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("ekb.advText")), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex flex-col gap-[20px] justify-between px-[24px] rounded-[8px] flex-1 py-[24px] bg-[#F9FAFB]",
            key: item.id
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("img", {
                  src: item.icon
                }, null, 8 /* PROPS */, _hoisted_9)
              ]),
              _createElementVNode("div", _hoisted_10, _toDisplayString(item.title), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_11, _toDisplayString(item.desc), 1 /* TEXT */)
            ]),
            _createElementVNode("a", _hoisted_12, _toDisplayString(_ctx.$t('solution.knowledge.demo')), 1 /* TEXT */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}