export const product = {
  "banner": {
    "headline1": "从算力到应用",
    "headline2": "大模型引领软件工程变革",
    "solution": "OpenCSG 解决方案"
  },
  "starCloud": {
    "headline": "OpenCSG StarCloud 星云平台",
    "desc": "StarCloud 是一个基于深度学习的一站式大模型开发及服务运行平台，提供完整的数据集管理、模型训练、评估和推理服务，特点包括便捷的发布方式、稳定的运行环境，以及企业级的服务安全，旨在支持各行业的生成式AI应用并帮助客户构建高精度、高性能的大模型。",
    "demoVideo": "演示视频",
    "demo": "Demo体验",
    "remarks": "暂不开放注册，请联系 {contact} 获取试用权限",
    "part1": "大模型训练和微调",
    "part1-": "StarCloud 平台的核心功能是大模型的训练和微调，允许用户查看训练状态并选择训练方式，同时支持企业客户根据业务需求对模型进行精调，使用专有数据集降低成本并优化特定任务的表现。",
    "part2": "流程完善、发布便捷",
    "part2-": "提供全流程服务：涵盖数据集管理、模型训练、服务发布与监管的一站式服务。可视化管理：通过直观的界面实现对大模型全生命周期的管理，简化从数据到服务的实施过程。",
    "part3": "运行稳定、共建生态",
    "part3-": "完整技术栈：平台提供完整的技术栈和长期稳定的模型开发引擎，确保算法的稳定性和性能。低技术门槛：适用于各行各业，降低接入门槛，助力完成行业大模型的开发建设。",
    "part4": "安全可靠、一键启用",
    "part4-": "企业级服务：提供安全可信的企业级服务，结合底层内容安全功能，确保推理内容的审核与敏感词过滤。算法数据保护：采用企业级多租户设计，保护数据与安全隐私",
  },
  "starShip": {
    "headline": "OpenCSG StarShip 星舰平台",
    "desc": "StarShip 致力于为大模型应用开发团队提供一套完整的模型/数据管理及应用搭建平台，利用大模型技术打造软件工程2.0的新体验",
    "demo": "产品演示",
    "trailApply": "申请试用",
    "ideApply": "IDE 插件"
  },
}
