import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menuItem min-h-max flex md:items-center gap-[16px] py-[10px]" }
const _hoisted_2 = { class: "pt-[6px] md:pt-0" }
const _hoisted_3 = { class: "max-w-[247px] flex flex-col whitespace-normal" }
const _hoisted_4 = { class: "text-[#101828] text-[16px] flex gap-[8px] items-center" }
const _hoisted_5 = { class: "text-[14px] w-[247px] md:w-[unset] text-[#475467] font-light leading-[20px] md:hidden" }

export function render(_ctx, _cache) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SvgIcon, { name: "navbar_csghub" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.$t('navbar.csgHub')) + " ", 1 /* TEXT */),
        _createVNode(_component_SvgIcon, {
          name: "hot_fire",
          width: "16",
          height: "16"
        })
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('navbar.csgHubText')), 1 /* TEXT */)
    ])
  ]))
}