import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-[32px] md:px-[10px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DiscussionCards = _resolveComponent("DiscussionCards")
  const _component_NewCommunityDiscussion = _resolveComponent("NewCommunityDiscussion")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($data.theFlag=='show')
      ? (_openBlock(), _createBlock(_component_DiscussionCards, {
          key: 0,
          cards: $data.cards,
          onGetDiscussion: $options.getDiscussion,
          onUpdateDetails: $options.updateDetails,
          onChangeFlag: $options.changeFlag
        }, null, 8 /* PROPS */, ["cards", "onGetDiscussion", "onUpdateDetails", "onChangeFlag"]))
      : _createCommentVNode("v-if", true),
    ($data.theFlag=='new')
      ? (_openBlock(), _createBlock(_component_NewCommunityDiscussion, {
          key: 1,
          type: $props.type,
          onGetDiscussion: $options.getDiscussion,
          onChangeFlag: $options.changeFlag,
          localModelId: $props.localModelId
        }, null, 8 /* PROPS */, ["type", "onGetDiscussion", "onChangeFlag", "localModelId"]))
      : _createCommentVNode("v-if", true)
  ]))
}