export const billing = {
  title: 'Billing',
  balance: 'Account Balance',
  inference: 'Inference Instances',
  finetune: 'Finetune Instance',
  instanceID: 'Instance ID',
  createTime: 'Creation Time',
  tokenNum: 'Token Num',
  usageTime: 'Total Duration(m)',
  cost: 'Cost(yuan)',
  status: 'Status',
  details: 'Details',
  total: 'Total',
  refresh: 'Refresh',
  instanceBilling: 'Instance Billing',
  billing: 'Billing',
  Running: 'Running',
  Stopped: 'Stopped'
}
