<template>
  <div
    class="max-w-[1280px] m-auto md:px-[20px] sm:px-[16px] text-center px-8 relative"
  >
    <div class="star_ship_banner pt-16 pb-24 md:pb-16">
      <img
        src="images/ide/ideDot.png"
        class="topDot"
        alt=""
      />
      <div class="relative">
        <div class="flex items-center justify-center mb-[16px]">
          <SvgIcon
            name="notice"
            class="w-[40px] h-[40px] md:w-[24px] md:h-[24px] mr-[8px]"
          />
          <div
            class="flex items-center justify-start px-[8px] py-[2px] bg-[#fff] rounded-[6px] gap-[6px] noticeCont"
          >
            <SvgIcon
              name="dot"
              class="w-[8px] h-[8px] mr-[6px]"
            />
            <p class="text-[14px] text-[#344054]">
              {{ $t('starShipIde.tip') }}
            </p>
          </div>
        </div>
        <div
          class="text-[72px] leading-[90px] md:text-[36px] md:leading-[44px] mb-[24px] text-[#fff]"
        >
          Starship CodeSouler
        </div>
        <div class="secTit text-[36px] font-medium mb-[12px]">
          {{ $t('starShipIde.title') }}
        </div>
        <p class="text-[20px] text-[#D0D5DD]">
          {{ $t('starShipIde.desc') }}
        </p>
        <p class="text-[20px] text-[#D0D5DD]">{{ $t('starShipIde.desc1') }}</p>
        <div
          class="flex items-center justify-center gap-[12px] mb-[64px] mt-[48px] sm:flex-wrap"
        >
          <div
            class="cursor-pointer md:w-full py-4 px-[22px] text-[18px] leading-[28px] text-[#344054] bg-[#FFF] border border-[#D0D5DD] rounded-[8px] btn1 hover:bg-[#F9FAFB] sm:w-full"
            @click="
              toSecPage(
                'https://opencsg.com/docs/StarShip/CodeSouler/CodeSouler_intro'
              )
            "
          >
            {{ $t('starShipIde.docBtn') }}
          </div>
          <div
            class="cursor-pointer md:w-full py-4 px-[22px] text-[18px] leading-[28px] text-[#FFF] bg-[#3250BD] border border-[#3250BD] rounded-[8px] flex items-center justify-center hover:bg-[#223B99] hover:border-[#223B99] btn1 sm:w-full"
            @click="
              toSecPage(
                'https://marketplace.visualstudio.com/items?itemName=OpenCSG.codesouler'
              )
            "
          >
            <img
              class="w-[21px] mr-[10px]"
              src="images/ide/vscodeBtnIco.png"
              alt=""
            />
            VS Code
          </div>
          <div
            class="cursor-pointer md:w-full py-4 px-[22px] text-[18px] leading-[28px] text-[#FFF] bg-[#3250BD] border border-[#3250BD] rounded-[8px] flex items-center justify-center hover:bg-[#223B99] hover:border-[#223B99] btn1 sm:w-full"
            @click="
              toSecPage('https://plugins.jetbrains.com/plugin/24737-codesouler')
            "
          >
            <img
              class="w-[22.5px] mr-[10px]"
              src="images/ide/jtBtnIco.png"
              alt=""
            />
            JetBrains
          </div>
        </div>
        <div
          class="w-[86%] max-w-[800px] md:w-full m-auto p-[16px] rounded-[24px] ideImgBox sm:w-full"
        >
          <img
            class="w-full"
            src="images/ide/1.jpg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  const toSecPage = (url) => {
    window.open(url, '_blank')
  }
</script>
<style lang="less" scoped>
  .topDot {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    z-index: 0;
  }
  .star_ship_banner {
    background-image: url('../images/ideTopBg.png');
    background-repeat: repeat;
    background-size: auto;
    background-position: top;
    border-radius: 40px;
    overflow: hidden;
  }
  .secTit {
    background: linear-gradient(
      90deg,
      #b9ceff 18.7%,
      #4d5df6 40.37%,
      #6a1ed2 69.79%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .ideImgBox {
    border: 2px solid #475467;
    img {
      border-radius: 13.5px;
    }
  }
  .btn1 {
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    transition: all 0.3s ease-in-out;
  }
  .noticeCont {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 1);
    animation: breathing 3s infinite;
  }

  @keyframes breathing {
    0% {
      box-shadow: 0 0 26px 26px rgba(52, 152, 219, 0);
    }
    50% {
      box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7);
    }
    100% {
      box-shadow: 0 0 26px 26px rgba(52, 152, 219, 0);
    }
  }
</style>
