<template>
  <el-card>
    <el-descriptions
      title="具身智能文章"
      :column="2"
      border
    >
      <el-descriptions-item label="标题">{{ eaiArticle.title }}</el-descriptions-item>
      <el-descriptions-item label="状态">{{ statusMap[eaiArticle.status] }}</el-descriptions-item>
      <el-descriptions-item label="封面">
        <img :src="eaiArticle.cover_url" alt="cover" class="h-[200px] object-cover" />
      </el-descriptions-item>
      <el-descriptions-item label="访问量">{{ eaiArticle.view_count }}</el-descriptions-item>
      <el-descriptions-item label="描述" :span="2">{{ eaiArticle.description }}</el-descriptions-item>
      <el-descriptions-item label="相关文章" :span="2">
        <div class="flex flex-cols gap-3">
          <a :href="`/new_admin/eai_articles/${item.id}`" v-for="item in relatedEaiArticles" :key="item.id">
            <el-link type="primary">{{ item.title }}</el-link>
          </a>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="相关设备" :span="2">
        <div class="flex flex-cols gap-3">
          <router-link :to="`/new_admin/eai_devices/${item.id}`" v-for="item in eaiDevices" :key="item.id">
            <el-link type="primary">{{ item.title }}</el-link>
          </router-link>
        </div>
      </el-descriptions-item>
      <!-- codes -->
      <el-descriptions-item label="代码仓库" :span="2">
        <div class="flex flex-cols gap-3">
          <router-link :to="`/new_admin/codes/${code_path}`" v-for="code_path in eaiArticle.code_paths" :key="code_path">
            <el-link type="primary">{{ code_path }}</el-link>
          </router-link>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    
    <el-divider />

    <el-descriptions
      direction="vertical"
      :column="1"
      border
    >
      <el-descriptions-item label="正文内容" :span="1">
        <div class="flex flex-col-reverse">
          <CKContent :content="eaiArticle.content" />
        </div>
      </el-descriptions-item>

    </el-descriptions>
    <template #footer>
      <div class="card-footer">
        <router-link :to="`/new_admin/eai_articles/${eaiArticle.id}/edit`">
          <el-button class="w-[100px]">编辑</el-button>
        </router-link>
      </div>
    </template>
  </el-card>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { useRouter } from 'vue-router'
  import { eaiArticleDetail, eaiArticleList, eaiDeviceList } from "../../../api/csrf/eai"
  import CKContent from "../../shared/viewers/CKContent.vue";

  const router = useRouter();
  
  const eaiArticle = ref({});
  const relatedEaiArticles = ref([]);
  const eaiDevices = ref([]);

  const statusMap = {
    draft: '草稿',
    published: '已发布',
  }

  const fetchEaiArticles = (params) => {
    eaiArticleList(params).then((res) => {
      relatedEaiArticles.value = res.data;
    })
  };

  const fetchEaiDevices = (params) => {
    eaiDeviceList(params).then((res) => {
      eaiDevices.value = res.data;
    })
  };

  onMounted(() => {
    const id = router.currentRoute.value.params.id
    eaiArticleDetail(id).then((res) => {
      const { data } = res;
      eaiArticle.value = data;

      const { related_eai_article_ids, eai_device_ids } = data

      if (related_eai_article_ids.length > 0) {
        fetchEaiArticles({ ids: related_eai_article_ids });
      }
      if (eai_device_ids.length > 0) {
        fetchEaiDevices({ ids: eai_device_ids });
      }
    });
  });
</script>