import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e4938da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full mt-8 mb-8 rounded-t-lg pt-9 xl:px-10 md:px-0 md:h-auto border border-[#EBEEF5]" }
const _hoisted_2 = { class: "flex justify-between px-6 mb-5" }
const _hoisted_3 = { class: "text-[18px] leading-[28px]" }
const _hoisted_4 = { class: "flex gap-3 items-center" }
const _hoisted_5 = { class: "flex justify-center gap-[12px] items-center pl-3" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = {
  key: 0,
  class: "text-[14px] font-[300] leading-[20px] text-[#475467]"
}
const _hoisted_8 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_9 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_10 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_11 = { class: "mt-[12px] mb-[16px] flex justify-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('billing.instanceBilling')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('billing.total')) + "：￥" + _toDisplayString((Math.abs($setup.totalPrice)/100.0).toFixed(2)), 1 /* TEXT */),
        _createVNode(_component_el_date_picker, {
          modelValue: $setup.selectedMonth,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.selectedMonth) = $event)),
          onChange: $setup.dateChange,
          type: "month",
          placeholder: "Pick",
          "disabled-date": $setup.disabledDate,
          style: {"width":"120px"}
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_el_button, {
          loading: $setup.billingLoading,
          class: "flex gap-1 border border-[#D0D5DD] rounded-[8px] py-1 px-2 cursor-pointer",
          onClick: _cache[1] || (_cache[1] = $event => ($setup.fetchDetails(1)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('billing.refresh')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["loading"])
      ])
    ]),
    _createElementVNode("div", null, [
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "billing-table",
        "header-row-class-name": "billing-table-header-row",
        "header-cell-class-name": "billing-table-header-cell",
        "row-class-name": "billing-table-row",
        "cell-class-name": "billing-table-row-cell",
        data: $setup.billings,
        stripe: "",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createCommentVNode(" instance ID "),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('billing.instanceID'),
            "label-class-name": "indent-3 text-[12px] justify-center font-[400] leading-[18px] text-[#475467]",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (!!scope.row.instance_name)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, " #" + _toDisplayString(scope.row.instance_name), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createCommentVNode(" usage time "),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('billing.usageTime'),
            "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_8, _toDisplayString(scope.row.consumption), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createCommentVNode(" cost "),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('billing.cost'),
            "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_9, _toDisplayString((Math.abs(scope.row.value)/100.0).toFixed(2)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createCommentVNode(" created_at "),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('billing.createTime'),
            "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_10, _toDisplayString($setup.formatDateTime(scope.row.created_at)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"])), [
        [_directive_loading, $setup.billingLoading]
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode($setup["CsgPagination"], {
        perPage: $setup.perPage,
        currentPage: $setup.currentPage,
        onCurrentChange: $setup.fetchDetails,
        total: $setup.totalBillings
      }, null, 8 /* PROPS */, ["perPage", "currentPage", "total"])
    ])
  ]))
}