import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center md:flex-col px-[24px] rounded-[8px] md:px-[50px] sm:px-[20px] max-w-[1280px] m-auto bg-white" }
const _hoisted_2 = { class: "grow flex flex-col gap-[24px] px-6 py-10 border-l min-h-[calc(100vh-153px)]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Menu"], {
      class: "max-w-[411px] md:mb-[24px]",
      name: $props.name,
      email: $props.email,
      displayName: $props.displayName,
      avatar: $props.avatar
    }, null, 8 /* PROPS */, ["name", "email", "displayName", "avatar"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["BillingCard"])
    ])
  ]))
}