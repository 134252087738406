import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "videoDialog" }
const _hoisted_2 = {
  ref: "videoPlayer",
  style: {"width":"100%"},
  controls: "",
  autoplay: "",
  muted: ""
}
const _hoisted_3 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: $data.dialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.dialogVisible) = $event)),
      onClose: $options.closeDialog,
      width: $options.dialogWidth,
      style: { borderRadius: '5px' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("video", _hoisted_2, [
          _createElementVNode("source", {
            src: $data.theVideoSrc,
            type: "video/mp4"
          }, null, 8 /* PROPS */, _hoisted_3)
        ], 512 /* NEED_PATCH */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "onClose", "width"])
  ]))
}