import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border border-[#DCDFE6] rounded-[8px] my-[32px] md:my-0 md:border-none px-[24px] py-[24px]" }
const _hoisted_2 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_3 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_4 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_5 = { class: "flex flex-col gap-[6px]" }
const _hoisted_6 = { class: "flex flex-col gap-[6px]" }
const _hoisted_7 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_8 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_9 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_10 = { class: "flex flex-col gap-[6px]" }
const _hoisted_11 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_12 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_13 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_14 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_15 = { class: "flex flex-col gap-[6px]" }
const _hoisted_16 = { class: "text-[#344054] text-[14px]" }
const _hoisted_17 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_18 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_19 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_20 = { class: "flex flex-col gap-[6px]" }
const _hoisted_21 = { class: "text-[#344054] text-[14px]" }
const _hoisted_22 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_23 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_24 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_25 = { class: "flex flex-col gap-[6px]" }
const _hoisted_26 = { class: "text-[#344054] text-[14px]" }
const _hoisted_27 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_28 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_29 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_30 = { class: "flex flex-col gap-[6px]" }
const _hoisted_31 = { class: "text-[#344054] text-[14px]" }
const _hoisted_32 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_33 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_34 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_35 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_36 = { class: "text-black font-semibold" }
const _hoisted_37 = { class: "flex flex-col gap-[6px]" }
const _hoisted_38 = { class: "text-[#344054] text-[14px]" }
const _hoisted_39 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_40 = { class: "w-[380px] sm:w-full flex flex-col gap-[6px]" }
const _hoisted_41 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_42 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_43 = { class: "text-black font-medium" }
const _hoisted_44 = { class: "text-black font-medium break-words" }
const _hoisted_45 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_46 = { class: "text-black font-medium break-words" }
const _hoisted_47 = { class: "flex flex-col gap-[8px]" }
const _hoisted_48 = { class: "text-[#344054] text-[14px]" }
const _hoisted_49 = { class: "flex" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_divider = _resolveComponent("el-divider")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 暂停 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('endpoints.settings.stopEndpoint')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_button, {
            onClick: $setup.stopEndpoint,
            class: "w-[100px]",
            disabled: !$setup.initialized || $setup.isStopped
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('endpoints.settings.stop')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"])
        ])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 重启 "),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('endpoints.settings.restartEndpoint')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_el_button, {
          onClick: $setup.restartEndpoint,
          class: "w-[100px]",
          disabled: $setup.notInitialized
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('endpoints.settings.restart')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" cloud resource "),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('endpoints.settings.resource')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('endpoints.settings.resourceTip')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('endpoints.settings.currentCloudResource')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.currentResource,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.currentResource) = $event)),
          placeholder: "选择",
          size: "large",
          class: "!w-[512px] sm:!w-full",
          onChange: $setup.updateCloudResource,
          disabled: !$setup.isStopped
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cloudResources, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.name,
                label: item.name,
                value: item.id,
                disabled: !item.is_available
              }, null, 8 /* PROPS */, ["label", "value", "disabled"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "disabled"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" framework "),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('endpoints.settings.framework')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t('endpoints.settings.currentFramework')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.currentFrameworkId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.currentFrameworkId) = $event)),
          placeholder: "选择",
          size: "large",
          class: "!w-[512px] sm:!w-full",
          onChange: $setup.updateFramework,
          disabled: !$setup.isStopped
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filterFrameworks, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.frame_name,
                value: item.id
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "disabled"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" max replica "),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('endpoints.settings.maxReplica')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t('endpoints.settings.currentMaxReplica')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.currentMaxReplica,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.currentMaxReplica) = $event)),
          placeholder: "选择",
          size: "large",
          class: "!w-[512px] sm:!w-full",
          onChange: $setup.updateMaxReplica,
          disabled: !$setup.isStopped
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.replicaRanges, (item) => {
              return _createVNode(_component_el_option, {
                key: item,
                label: item,
                value: item
              }, null, 8 /* PROPS */, ["label", "value"])
            }), 64 /* STABLE_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "disabled"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" min replica "),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t('endpoints.settings.minReplica')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.$t('endpoints.settings.currentMinReplica')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.currentMinReplica,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.currentMinReplica) = $event)),
          placeholder: "选择",
          size: "large",
          class: "!w-[512px] sm:!w-full",
          onChange: $setup.updateMinReplica,
          disabled: !$setup.isStopped
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.replicaRanges, (item) => {
              return _createVNode(_component_el_option, {
                key: item,
                label: item,
                value: item
              }, null, 8 /* PROPS */, ["label", "value"])
            }), 64 /* STABLE_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "disabled"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 修改可见性 "),
    _createElementVNode("div", _hoisted_32, [
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t('endpoints.settings.changeVisibility')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_35, [
          _createTextVNode(_toDisplayString(_ctx.$t('endpoints.settings.statusText')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_36, "【" + _toDisplayString($setup.repoDetailStore.isPrivate ? $setup.t('all.private') : $setup.t('all.public')) + "】", 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('endpoints.settings.status')) + "。" + _toDisplayString($setup.repoDetailStore.isPrivate
              ? $setup.t('endpoints.settings.privateVis')
              : $setup.t('endpoints.settings.publicVis')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.$t('endpoints.settings.visibility')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.visibilityName,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.visibilityName) = $event)),
          onChange: $setup.changeVisibility,
          placeholder: "Select",
          size: "large",
          class: "!w-[512px] sm:!w-full",
          disabled: !$setup.isStopped
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.visibilityOptions, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.label,
                value: item.value
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "disabled"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 删除 "),
    _createElementVNode("div", _hoisted_39, [
      _createElementVNode("div", _hoisted_40, [
        _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t('endpoints.settings.deleteEndpoint')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_42, [
          _createTextVNode(_toDisplayString(_ctx.$t('endpoints.settings.delTips')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.$t('all.canNot')), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('endpoints.settings.delTips2')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_44, _toDisplayString($props.endpointName), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('endpoints.settings.delTips3')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_45, [
          _createTextVNode(_toDisplayString(_ctx.$t('all.enterPls')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_46, _toDisplayString(`${$props.endpointName}/${$props.endpointId}`), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('all.sureDel')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_47, [
        _createElementVNode("p", _hoisted_48, _toDisplayString(_ctx.$t('endpoints.settings.namespaceName')), 1 /* TEXT */),
        _createVNode(_component_el_input, {
          modelValue: $setup.delDesc,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.delDesc) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_49, [
          _createElementVNode("div", {
            id: "confirmDelete",
            onClick: $setup.clickDelete,
            class: _normalizeClass(["text-[#98A2B3] py-[8px] px-[12px] text-[14px] leading-[20px] rounded-[8px]", 
              $setup.delDesc === `${$props.endpointName}/${$props.endpointId}`
                ? 'bg-[#D92D20] text-[#FFFFFF] cursor-pointer active:shadow-box active:space-y-0 active:space-x-0 active:ring-4 active:ring-red-400 active:ring-opacity-25 active:bg-[#D92D20] hover:text-white'
                : 'bg-[#F2F4F7]'
            ]),
            onMouseover: $setup.handleMouseOver,
            onMouseleave: $setup.handleMouseLeave
          }, _toDisplayString(_ctx.$t('endpoints.settings.confirmDel')), 35 /* TEXT, CLASS, NEED_HYDRATION */)
        ])
      ])
    ])
  ]))
}