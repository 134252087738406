import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    class: "item",
    effect: "dark",
    content: $props.content,
    placement: "top"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["SvgIcon"], {
        name: "input_tip",
        class: "cursor-pointer"
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["content"]))
}