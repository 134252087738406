import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "pl-[10px] xl:pl-[25px] mb-[20px] font-semibold text-xl" }
const _hoisted_3 = { class: "grid grid-cols-3 xl:grid-cols-2 mlg:grid-cols-1 gap-[20px] justify-items-center xl:px-[20px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_SpaceCard = _resolveComponent("SpaceCard")
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('space.SpaceList')), 1 /* TEXT */),
      ($data.isLoggedInBoolean)
        ? (_openBlock(), _createBlock(_component_el_select, {
            key: 0,
            modelValue: $data.filterValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.filterValue) = $event)),
            onChange: $options.reloadCards,
            style: {"width":"100px"},
            class: "xl:mr-[20px]"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.filterValues, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item.value,
                  label: item.label,
                  value: item.value,
                  disabled: item.disabled
                }, null, 8 /* PROPS */, ["label", "value", "disabled"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "onChange"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse($data.theSpaces), (space) => {
        return (_openBlock(), _createBlock(_component_SpaceCard, {
          key: space.star_chain_id,
          title: space.title,
          desc: space.desc,
          author: space.author,
          "current-user-login-identity": $props.currentUserLoginIdentity,
          createdAt: space.created_at,
          coverImage: space.cover_image,
          tags: space.tags,
          status: space.status,
          starChainId: space.star_chain_id,
          spaceType: space.space_type,
          authorUuid: space.author_uuid
        }, null, 8 /* PROPS */, ["title", "desc", "author", "current-user-login-identity", "createdAt", "coverImage", "tags", "status", "starChainId", "spaceType", "authorUuid"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode(_component_el_pagination, {
      background: "",
      "current-page": $data.currentPage,
      "onUpdate:currentPage": [
        _cache[1] || (_cache[1] = $event => (($data.currentPage) = $event)),
        $options.nextPage
      ],
      "default-current-page": 1,
      total: Number($data.theTotalCards),
      "page-size": 6,
      layout: "prev, pager, next",
      class: "my-[52px] flex justify-center"
    }, null, 8 /* PROPS */, ["current-page", "total", "onUpdate:currentPage"])
  ], 64 /* STABLE_FRAGMENT */))
}