import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full overflow-hidden" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["StarShipBanner"]),
    _createVNode($setup["StarShipSection"]),
    _createVNode($setup["StarShipProductValue"])
  ]))
}