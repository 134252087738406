export const csghub = {
  "banner": {
    "headline1": "Open source ", 
    "headline2": "Asset management platform for large model native design", 
    "solution": "Create a secure, efficient and trusted environment for users to better manage and use large model core asset data", 
    "join": "Join the community"
  },
  "product": {
    "introduce": "Introduce",
    "headline": "Intelligent Data and AI Management Platform",
    "desc": "Provide comprehensive solutions to help you efficiently manage your assets, develop AI applications, synchronize data from multiple sources, and ensure data security compliance",
    "part1": "Comprehensive Asset Management System ",
    "part1-": "Efficiently manage assets such as model libraries, data sets, and code. Supports large files, provides Web side online editing function, and can preview data sets. Make your data management more organized and efficient through asset metadata management",
    "part1-p1": "Model base, data set, code and other asset management",
    "part1-p2": "Large File Support ",
    "part1-p3": "Online editing on the Web",
    "part1-p4": "Data set Preview",
    "part1-p5": "Asset Metadata Management",
    "part2": "Efficient AI Application Development Platform",
    "part2-": "On our platform, you can quickly demonstrate the capabilities of models, easily build and test application prototypes, and greatly improve development efficiency. Support one-click model inference services and model fine-tuning training to make AI application development easier",
    "part2-p1": "Ability to quickly display models in applications",
    "part2-p2": "Build and test the application prototype",
    "part2-p3": "One-click Start Model Inference Service",
    "part2-p4": "One-click Start Model fine-tuning training",
    "part3": "Multi-source data and model synchronization",
    "part3-": "Through our platform, you can view source data from the OpenCSG evangelical community and get model and dataset recommendations. At the same time, support to synchronize the OpenCSG spirit community model and data sets, making your resource management easier",
    "part3-p1": "View OpenCSG Communication community source Data",
    "part3-p2": "Model/Dataset Recommendation",
    "part3-p3": "Synchronizing OpenCSG spiritual community Model and data set",
    "part4": "Perfect safety compliance guarantee",
    "part4-": "We provide custom asset metadata capabilities to ensure compliance traceability and validation of models and licenses. Keep your data safe with data integrity verification, high availability and disaster recovery solutions",
    "part4-p1": "Custom Asset Metadata",
    "part4-p2": "Model and License compliance Traceability and Verification",
    "part4-p3": "Data set Integrity Check Mechanism",
    "part4-p4": "High Availability",
    "part4-p5": "Disaster Recovery",
    "feature-h": "CSGHub Product Features",
    "feature-p": "To create a secure, efficient and trusted environment for users to better manage and use large model core asset data",
    "feature-button": "Schedule product demo",
    "feature-h1": "Unified Asset Management",
    "feature-p1": "One-stop Hub for unified management of model files, data sets, and large model application code. Support HTTPS and SSH Git commands and Web interface operations",
    "feature-h2": "Large model capability expansion",
    "feature-p2": "native support versioning management, model format conversion, automatic data preprocessing, data set preview and other functions",
    "feature-h3": "Permissions and Security",
    "feature-p3": "Support system integration with enterprise users, support asset visible range setting, external and internal interface authentication design to meet enterprise security requirements",
    "feature-h4": "Private Deployment Support",
    "feature-p4": "No Internet dependence, no cloud vendor dependence and other external dependencies, can start privatization deployment with one click",
  },
  "price": {
    "introduce": "Price",
    "headline": "Choose the version that works for you",
    "desc": "Simple and transparent pricing",
    "community": "Community",
    "community-p1": "Version suitable for individuals or small groups",
    "asset": "Asset Management Module",
    "asset-p1": "Model Management",
    "asset-p2": "Data Set Management",
    "asset-p3": "Code Repository Management",
    "asset-p4": "Large file support",
    "asset-p5": "Web side online editing",
    "asset-p6": "Data set View",
    "asset-p7": "asset Metadata Management Mechanism",
    "asset-p8": "Basic Admin Dashboard",
    "ai": "AI Application",
    "ai-p1": "Application space",
    "ai-p2": "One-click Model inference Service",
    "ai-p3": "One-click Start Model fine-tuning Training",
    "ai-p4": "Data Format conversion",
    "ai-p5": "Asset Copilot",
    "data": "Multi-source data synchronization",
    "data-p1": "Sync OpenCSG Spiritual Community Dataset (restricted)",
    "data-p2": "Synchronous OpenCSG Spiritual Community Model (restricted)",
    "data-p3 ":" View OpenCSG Communication community source data",
    "portal": "Community Support",
    "portal-p1": "Get community support through OpenCSG community or GitHub",
    "company": "Enterprise",
    "company-desc": "Includes more advanced features and support services beyond the community Edition",
    "unit": "/ year/user",
    "aiPlus": "AI Application Enhancement",
    "aiPlus-p1": "Model Inference Optimization",
    "aiPlus-p2": "Privatised model asset Copilot",
    "dataCompany": "Multi-source data synchronization",
    "dataCompany-p1": "Model/Dataset Recommendation ",
    "dataCompany-p2": "Synchronous OpenCSG Vivid Community Model",
    "dataCompany-p3": "Synchronizing OpenCSG Spiritual Community Data Set",
    "safe": "Safety compliance",
    "safe-p1": "Custom asset Metadata",
    "safe-p2": "Data Integrity check mechanism",
    "safe-p3": "High Availability",
    "safe-p4": "Disaster Recovery",
    "safe-p5": "Model and License compliance traceability and Verification",
    "made": "Custom ability",
    "made-p1": "Enterprise-level fine-grained permission Control",
    "made-p2": "Enterprise Admin Dashboard",
    "made-p3": "Interface customization, 8000/ person-day",
    "technology": "Technical Support",
    "technology-p1": "OpenCSG official work order system, 7x24 online service",
    "technology-p2": "Expert Support Service (remote + on-site), 8000/ man-day",
    "capacity": "indicates the resource capacity",
    "capacity-p1": "Synchronize 100 models",
    "free": "Free to use",
    "console": "Contact Sales",
    "recommend": "Recommend",
    "month": "monthly payment",
    "year": "annual payment"
  },
  "serve": {
    "recommend": "Online experience",
    "headline": "Online SaaS Fast Experience",
    "desc": "Growing with millions of users in the OpenCSG large Model community",
    "doc": "Document Center",
    "register": "Register to use"
  }
}
