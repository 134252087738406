export const starShipIde = {
  tip:'Get 1,000,000 Free Tokens When Signing Up!',
  title: 'Empower Your Development: Your AI Coding Soulmate',
  desc: 'CodeSouler: Boosting Efficiency, Elevating Code Quality, ',
  desc1: 'and Simplifying Your Development Process. Optimize Your Software Lifecycle.',
  docBtn:'Documentation',
  secTip1:'Code Generation and AI Q&A',
  secTit1:'Code Generation and Q&A: Enhancing Your Entire Development Process',
  listItemTip1:'Smart Code Comments',
  listItemTit1:'Automatically analyze code logic, add precise and detailed comments, boost readability, and reduce communication costs.',
  listItemTip2:'Code Optimization & Refactoring',
  listItemTit2:'Automatically remove redundant code, optimize variable names, fix errors, and elevate code quality.',
  listItemTip3:'Code Explanation & Answers',
  listItemTit3:'Real-time responses to your queries, offering timely and expert assistance with project architecture and code content.',
  secTip2:'Unit Test',
  secTit2:'High-Quality Unit Test Suite: Ensuring Code Stability and Reliability',
  secDec1:'CodeSouler can automatically generate comprehensive test suites tailored to your codebase. These test cases cover the main paths ',
  secDec2:'and boundary situations of the code, ensuring that the code remains stable and reliable during modification and extension.',
  boxItem1Tip:'Use "/test" Opeartion',
  boxItem1Tit:'Select the target Code Block, use shortcut Ctrl + L , and CodeSouler will pop up the session window. And reference the selected Code Block to the session box, and then enter the /test command. CodeSouler can write high-quality test cases for the selected code',
  boxItem2Tip:'Prompt Writing for Chat Box',
  boxItem2Tit:'Selecting the specified Code Block, write the prompt "Please write test cases for this code", CodeSouler can also generate unit test cases for code. ',
  boxItem3Tip:'"Generate Test" - Single Test Case Creation',
  boxItem3Tit:'CodeSouler lets you quickly generate test cases directly in the code interface. Click "Generate Test" next to any function or class to configure and create tailored test cases in seconds.',
  boxItem4Tip:'"Generate test"  - Multiple Test Case Creation',
  boxItem4Tit:'CodeSouler leverages a comprehensive testing suite based on framework, paths, samples, and test count, enabling the simultaneous generation of multiple qualifying test cases.',
  secTip3:'CodeReview',
  secTit3:'Precise and Efficient Code Review: Safeguarding Your Code for Deployment',
  secDec3:'CodeSouler not only supports code review for blocks and files but also offers CodeReview for Git diffs. With intelligent analysis and suggestions, it significantly enhances the efficiency and accuracy of code reviews.',
  btnText1:'Snippet',
  btnText2:'File',
  secTip4:'Comprehensive Support for Multiple Programming Languages and Major IDEs',
  secTit4:'Supports 100+ Programming Languages and Major IDEs like VSCode and the JetBrains Suite',
  imgText:'Programming Languages',
}
