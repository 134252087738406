export const form = {
  "contactUs": {
    "contact": "Contact us",
    "name": "Name",
    "company": "Company (please fill in the business information name!)",
    "email": "Email",
    "phone": "Phone",
    "job": "Position",
    "help": "What help are you looking for?",
    "submit": "Submit",
    "close": "Close",
  },
  "expert": {
    "tip": "The information filled in the form, except for the phone number, will be displayed on the expert recommendation page.",
    "p1": "OpenCSG invites you to join the expert community, allowing us to jointly build an open source and shared open source ecosystem. We hope you can provide technical support and consultation, solution design guidance, etc. for users.",
    "p2": "You can be an artificial intelligence expert, software development expert, open-source expert, algorithm expert, legal expert, etc. We sincerely welcome you to join the expert community.",
    "name": "Name",
    "job": "Position Name",
    "phone": "Phone number",
    "company": "Company name",
    "expertise": "Areas of expertise",
    "desc": "Personal Introduction",

    "nameWarn": "Please fill in your name",
    "jobWarn": "Please fill in the position name",
    "phoneWarn": "Please fill in your phone number",
    "companyWarn": "Please fill in the company name",
    "expertiseWarn": "Please fill in your area of expertise",
    "descWarn": "Please fill in your personal introduction",
    "success": "The form was successfully sent",
  },
  "partner": {
    "p1": "If you are interested in joining the OpenCSG Open Life Community and becoming our partner, please fill in the following information",
    "p2": "We will contact you as soon as possible and communicate in detail. Thank you for your support of OpenCSG.",
    "name": "Name",
    "name-": "Your name",
    "phone": "Phone number",
    "email": "Work email address",
    "company": "Company/Unit Name",
    "company-": "Open and vivid",
    "expertise": "Professional title",
    "expertise-": "CEO",
    "web": "Company/Product Official Website",
    "web-": "example: https://opencsg.com",
    "industry": "Company industry",
    "industry-": "Please fill in your industry information",
    "desc": "Please briefly describe your collaboration needs",
    "desc-": "Please provide your cooperation intention and needs",
    "submit": "Submit",

    "nameWarn": "Please fill in your name",
    "phoneWarn": "Please fill in your phone number",
    "emailWarn": "Please fill in your email address",
    "companyWarn": "Please fill in the company/unit name",
    "expertiseWarn": "Please fill in your professional title",
    "webWarn": "Please fill in the company/product official website",
    "industryWarn": "Please choose your company's industry",
  }
}
