<template>
  <el-form
    ref="dataFormRef"
    :model="dataForm"
    :rules="rules"
    class="w-full flex flex-col gap-[14px]"
    label-position="top"
  >
    <el-form-item
      label="标题"
      prop="title"
      class="w-full"
    >
      <el-input v-model="dataForm.title" />
    </el-form-item>
    <!-- status -->
    <el-form-item
      label="状态"
      prop="status"
      class="w-full"
    >
      <el-select v-model="dataForm.status" placeholder="请选择">
        <el-option label="草稿" value="draft" />
        <el-option label="发布" value="published" />
      </el-select>
    </el-form-item>
    <el-form-item
      label="描述"
      prop="description"
      class="w-full"
    >
      <el-input
        :rows="3"
        type="textarea"
        v-model="dataForm.description"
      />
    </el-form-item>
    <!-- related_eai_article_ids -->
    <el-form-item
      label="相关文章"
      prop="related_eai_article_ids"
      class="w-full"
    >
      <el-select
        v-model="dataForm.related_eai_article_ids"
        remote
        :reserve-keyword="false"
        default-first-option
        :remote-method="(v) => fetchEaiArticles({keyword: v})"
        :loading="relatedEaiArticleLoading"
        multiple
        clearable
        filterable
        placeholder="请选择相关文章(title description 模糊查询)"
      >
        <el-option
          v-for="item in relatedEaiArticles"
          :key="item.id"
          :label="`${item.title} | ${item.description}`"
          :value="item.id"
        />
      </el-select>
    </el-form-item>

    <!-- eai_device_ids -->
    <el-form-item
      label="设备"
      prop="eai_device_ids"
      class="w-full"
    >
      <el-select
        v-model="dataForm.eai_device_ids"
        remote
        :reserve-keyword="false"
        default-first-option
        :remote-method="(v) => fetchEaiDevices({keyword: v})"
        :loading="eaiDeviceLoading"
        multiple
        clearable
        filterable
        placeholder="请选择设备"
      >
        <el-option
          v-for="item in eaiDevices"
          :key="item.id"
          :label="`${item.title} | ${item.description}`"
          :value="item.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item
      label="代码仓库"
      prop="code_paths"
      class="w-full"
    >
      <el-select
        v-model="dataForm.code_paths"
        remote
        :reserve-keyword="false"
        default-first-option
        :remote-method="(v) => handleCodeSearch({search: v})"
        :loading="codeLoading"
        multiple
        clearable
        filterable
        placeholder="请选择代码仓库(名字查询)"
      >
        <el-option
          v-for="item in codeList"
          :key="item.path"
          :label="item.path"
          :value="item.path"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      label="访问量"
      prop="view_count"
      class="w-full"
    >
      <el-input v-model="dataForm.view_count" />
    </el-form-item>
    <el-form-item label="封面图" prop="cover_url">
      <el-upload
        :class="`w-[406px] ${showUpload ? 'h-[270px]' : 'hide'}`"
        :limit="1"
        v-model:file-list="dataForm.cover_image"
        drag
        list-type="picture-card"
        :headers="{ 'X-CSRF-TOKEN': csrf_token }"
        accept="image/png, image/jpeg, image/gif, image/svg+xml"
        :data="{namespace: 'daily_paper'}"
        action="/internal_api/upload"
        :on-progress="handleUploadProgress"
        :on-remove="handleRemoveImage"
        :on-success="handleUploadSuccess"
      >
        <div>
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            <p>
              <span class="text-[#223B99] font-[500]">
                点击
              </span>
              拖拽
            </p>
            <p>SVG, PNG, JPG 或 GIF</p>
          </div>
        </div>
      </el-upload>
    </el-form-item>
    <el-form-item
      label="正文内容"
      prop="content"
      class="w-full"
    >
      <CKEditor5 v-model:content="dataForm.content" />
    </el-form-item>

    <el-form-item class="w-full">
      <div class="flex justify-end w-full">
        <el-button
          type="primary"
          @click="handleSubmit"
        >
          提交
        </el-button>
      </div>
    </el-form-item>
  </el-form>

</template>

<script setup>
  import { ref, onMounted } from "vue";
  import CKEditor5 from "../../shared/form/CKEditor5.vue";
  import { eaiArticleCreate, eaiArticleUpdate, eaiArticleList, eaiDeviceList, eaiArticleDetail } from "../../../api/csrf/eai"
  import { codeSearch } from "../../../api/jwt/code"
  import { ElMessage } from 'element-plus'
  import { useRouter } from 'vue-router'
  const router = useRouter()

  const props = defineProps({
    eai_article: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        cover: "",
        view_count: 0,
        content: "",
      }),
    }
  });

  const showUpload = ref(true);
  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const codeList = ref([]);
  const codeLoading = ref(false);
  const relatedEaiArticles = ref([]);
  const relatedEaiArticleLoading = ref(false);
  const eaiDevices = ref([]);
  const eaiDeviceLoading = ref(false);

  const dataForm = ref(props.eai_article);
  const dataFormRef = ref(null);
  const rules = {
    title: [{ required: true, message: "标题必填", trigger: "blur" }],
    status: [{ required: true, message: "状态必填", trigger: "blur" }],
    description: [{ required: true, message: "描述必填", trigger: "blur" }],
    view_count: [{ required: true, message: "访问量必填", trigger: "blur" }],
    cover_url: [{ required: true, message: "封面必填", trigger: "blur" }],
    content: [{ required: true, message: "内容必填", trigger: "blur" }],
  };

  const handleUploadSuccess = (res) => {
    showUpload.value = false;
    dataForm.value.cover_url = res.url;
  };

  const handleRemoveImage = () => {
    showUpload.value = true;
    dataForm.value.cover_url = "";
  };

  const handleUploadProgress = () => {
    showUpload.value = false;
  };

  const handleCodeSearch = (params) => {
    codeLoading.value = true;
    codeSearch(params).then((res) => {
      codeList.value = res.data;
    }).finally(() => {
      codeLoading.value = false;
    });
  };

  const fetchEaiArticles = (params) => {
    relatedEaiArticleLoading.value = true;
    eaiArticleList(params).then((res) => {
      relatedEaiArticles.value = res.data;
    }).finally(() => {
      relatedEaiArticleLoading.value = false;
    });
  };

  const fetchEaiDevices = (params) => {
    eaiDeviceLoading.value = true;
    eaiDeviceList(params).then((res) => {
      eaiDevices.value = res.data;
    }).finally(() => {
      eaiDeviceLoading.value = false;
    });
  };



  const handleSubmit = () => {
    dataFormRef.value.validate((valid) => {
      if (valid) {
        createOrUpdate();
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  };

  const createOrUpdate = () => {
    const data = dataForm.value;

    if (dataForm.value.id) {
      update(dataForm.value);
    } else {
      create(dataForm.value);
    }
  };

  const create = (data) => {
    eaiArticleCreate(data).then((res) => {
      ElMessage({
        message: "创建成功",
        type: "success",
      });
      router.push({path: `/new_admin/eai_articles/${res.data.id}`});
    });
  };

  const update = (data) => {
    eaiArticleUpdate(data).then((res) => {
      ElMessage({
        message: "更新成功",
        type: "success",
      });
      router.push(`/new_admin/eai_articles/${res.data.id}`);
    });
  };

  onMounted(() => {
    const id = router.currentRoute.value.params.id

    if (id) {
      eaiArticleDetail(id).then(res => {
        const {data} = res;
        const { cover_url, code_paths, related_eai_article_ids, eai_device_ids } = data
        if (cover_url) {
          showUpload.value = false;
          data.cover_image = [{ url: cover_url }];
        }
        if (code_paths) {
          codeList.value = code_paths.map((path) => ({ path }));
        }
        if (related_eai_article_ids) {
          fetchEaiArticles({ ids: related_eai_article_ids });
        }
        if (eai_device_ids) {
          fetchEaiDevices({ ids: eai_device_ids });
        }
        dataForm.value = data;
      });
    }
  });
</script>

<style scoped>
  .el-form-item {
    margin-bottom: 24px;
  }
  .el-form-item__label {
    margin-bottom: 6px;
  }
  :deep(.el-input__wrapper) {
    border-radius: 8px;
  }
  :deep(.el-textarea__inner) {
    border-radius: 8px;
  }
  :deep(.el-upload--picture-card) {
    border: 0px;
  }
  :deep(.hide .el-upload.el-upload--picture-card){
    display: none;
  }
</style>
