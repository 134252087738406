import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sm:px-0 relative"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex gap-3 absolute left-[80px] bottom-[25px] sm:left-[50%] sm:transform sm:translate-x-[-50%] sm:translate-y-[-50%]" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "xl:px-[20px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")
  const _component_el_carousel = _resolveComponent("el-carousel")
  const _component_CampaignCard = _resolveComponent("CampaignCard")
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($options.hasRecommendedCampaigns)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_carousel, {
            autoplay: false,
            height: "390px"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse($data.theRecommendedCampaigns), (campaign) => {
                return (_openBlock(), _createBlock(_component_el_carousel_item, {
                  key: campaign.uuid
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("img", {
                        src: campaign.desktop_banner_url,
                        class: "sm:hidden w-full object-cover h-[390px]",
                        alt: "OpenCSG"
                      }, null, 8 /* PROPS */, _hoisted_2),
                      _createElementVNode("img", {
                        src: campaign.mobile_banner_url,
                        class: "hidden sm:block w-full object-cover h-[390px]",
                        alt: "OpenCSG"
                      }, null, 8 /* PROPS */, _hoisted_3)
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      (campaign.status === 'signing_up')
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: campaign.form_url,
                            class: "w-[96px] h-[40px] flex items-center justify-center bg-[#409EFF] rounded text-white text-[14px] leading-[22px]"
                          }, _toDisplayString(_ctx.$t('campaign.SignUp')), 9 /* TEXT, PROPS */, _hoisted_5))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("a", {
                        href: '/campaigns/' + campaign.id,
                        class: "w-[96px] h-[40px] flex items-center justify-center text-white rounded border-white border-[1px] text-[14px] leading-[22px]"
                      }, _toDisplayString(_ctx.$t('campaign.More')), 9 /* TEXT, PROPS */, _hoisted_6)
                    ])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse($data.theCampaigns), (campaign) => {
        return (_openBlock(), _createBlock(_component_CampaignCard, {
          campaignId: campaign.id,
          key: campaign.uuid,
          name: campaign.name,
          content: campaign.content,
          organizer: campaign.organizer,
          organizerWebsite: campaign.organizer_website,
          startDate: campaign.start_date,
          endDate: campaign.end_date,
          pageviews: campaign.pageviews,
          leadsCount: campaign.leads_count,
          formUrl: campaign.form_url
        }, null, 8 /* PROPS */, ["campaignId", "name", "content", "organizer", "organizerWebsite", "startDate", "endDate", "pageviews", "leadsCount", "formUrl"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode(_component_el_pagination, {
      background: "",
      "current-page": $data.currentPage,
      "onUpdate:currentPage": [
        _cache[0] || (_cache[0] = $event => (($data.currentPage) = $event)),
        $options.nextPage
      ],
      "default-current-page": 1,
      total: Number($data.theTotalCards),
      "page-size": 6,
      layout: "prev, pager, next",
      class: "my-[52px] flex justify-center"
    }, null, 8 /* PROPS */, ["current-page", "total", "onUpdate:currentPage"])
  ], 64 /* STABLE_FRAGMENT */))
}