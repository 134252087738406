export const starShipIde = {
  tip:'注册即送 1,000,000 免费 token 额度',
  title: '助力研发流程，您的智能Coding伴侣',
  desc: 'CodeSouler致力于提高开发效率、提高代码质量和简化研发流程',
  desc1: '旨在优化您的软件开发周期。',
  docBtn:'文档中心',
  secTip1:'代码生成 & 代码问答',
  secTit1:'智能代码生成与问答，助力开发全流程',
  listItemTip1:'智能代码注释',
  listItemTit1:'自动分析代码逻辑，添加准确详尽的注释 增加代码可读性，减少沟通成本',
  listItemTip2:'代码优化重构',
  listItemTit2:'自动移除冗余代码、优化变量命名、修复代码错误、全面提升代码质量',
  listItemTip3:'代码解释问答',
  listItemTit3:'实时解析用户提问，针对项目架构和 代码内容，提供及时、专业的解答',
  secTip2:'单元测试',
  secTit2:'高质量单元测试套件，确保代码稳定可靠',
  secDec1:'CodeSouler可以自动生成针对您的代码库量身定制的全面测试套件，这些测试用例覆盖了代码的主要路径和边界情况，',
  secDec2:'确保代码在修改和扩展过程中始终保持稳定可靠。',
  boxItem1Tip:'使用 /test 操作符',
  boxItem1Tit:'选中目标代码块，使用快捷键Ctrl + L，CodeSouler即会弹出会话窗口。并将选中代码块引用到会话框中，然后输入/test命令，CodeSouler即可对选中代码库编写高质量测试用例',
  boxItem2Tip:'会话框撰写prompt',
  boxItem2Tit:'选中指定代码块后，撰写prompt"请为这段代码编写测试用例"，CodeSouler也可以生成代码的单元测试用例。',
  boxItem3Tip:'"Generate test" - 单测试用例生成',
  boxItem3Tit:'CodeSouler 支持在代码界面快速生成测试用例。每个函数或类名前都有“Generate test”选项，点击后，用户可以配置测试框架、路径、样例和测试数量，快速生成对应的测试用例。',
  boxItem4Tip:'"Generate test" - 多测试用例生成',
  boxItem4Tit:'CodeSouler依托一套基于测试框架、测试路径、测试样例和生成测试个数的完整测试套件，可以同时生成多个满足条件的测试用例',
  secTip3:'代码审查',
  secTit3:'精准高效代码审查，为代码上线保驾护航',
  secDec3:'CodeSouler不仅支持对代码块、文件的代码审查，还提供了对Git diff的CodeReview功能，通过智能化的分析和建议，极大地提升了代码审查的效率和准确性。',
  btnText1:'代码片段',
  btnText2:'代码文件',
  secTip4:'海量编程语言和主流IDE全方位支持',
  secTit4:'支持 100 多种主流语言，支持 VSCode、JetBrains 全系列等主流 IDE',
  imgText:'计算机编程语言',
}
