import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "flex flex-cols gap-3" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "flex flex-cols gap-3" }
const _hoisted_5 = { class: "flex flex-cols gap-3" }
const _hoisted_6 = { class: "flex flex-col-reverse" }
const _hoisted_7 = { class: "card-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")
  const _component_el_link = _resolveComponent("el-link")
  const _component_router_link = _resolveComponent("router-link")
  const _component_el_descriptions = _resolveComponent("el-descriptions")
  const _component_el_divider = _resolveComponent("el-divider")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_card = _resolveComponent("el-card")

  return (_openBlock(), _createBlock(_component_el_card, null, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          to: `/new_admin/eai_articles/${$setup.eaiArticle.id}/edit`
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, { class: "w-[100px]" }, {
              default: _withCtx(() => [
                _createTextVNode("编辑")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_descriptions, {
        title: "具身智能文章",
        column: 2,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "标题" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.eaiArticle.title), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "状态" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.statusMap[$setup.eaiArticle.status]), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "封面" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: $setup.eaiArticle.cover_url,
                alt: "cover",
                class: "h-[200px] object-cover"
              }, null, 8 /* PROPS */, _hoisted_1)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "访问量" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.eaiArticle.view_count), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "描述",
            span: 2
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.eaiArticle.description), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "相关文章",
            span: 2
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.relatedEaiArticles, (item) => {
                  return (_openBlock(), _createElementBlock("a", {
                    href: `/new_admin/eai_articles/${item.id}`,
                    key: item.id
                  }, [
                    _createVNode(_component_el_link, { type: "primary" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ], 8 /* PROPS */, _hoisted_3))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "相关设备",
            span: 2
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.eaiDevices, (item) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    to: `/new_admin/eai_devices/${item.id}`,
                    key: item.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_link, { type: "primary" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createCommentVNode(" codes "),
          _createVNode(_component_el_descriptions_item, {
            label: "代码仓库",
            span: 2
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.eaiArticle.code_paths, (code_path) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    to: `/new_admin/codes/${code_path}`,
                    key: code_path
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_link, { type: "primary" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(code_path), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_divider),
      _createVNode(_component_el_descriptions, {
        direction: "vertical",
        column: 1,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, {
            label: "正文内容",
            span: 1
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode($setup["CKContent"], {
                  content: $setup.eaiArticle.content
                }, null, 8 /* PROPS */, ["content"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}