import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_arrow_down = _resolveComponent("arrow-down")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dropdown, null, {
      dropdown: _withCtx(() => [
        _createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: `/endpoints/new?model_id=${$props.modelId}`
                }, _toDisplayString($setup.t("endpoints.new.dedicated")), 9 /* TEXT, PROPS */, _hoisted_1)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "default",
          class: "!rounded-lg"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.t("all.deploy")) + " ", 1 /* TEXT */),
            _createVNode(_component_el_icon, { class: "ml-1 el-icon--right" }, {
              default: _withCtx(() => [
                _createVNode(_component_arrow_down)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}