import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex rounded-2xl border border-[#EAECF0] my-6 md:m-5 md:flex-col" }
const _hoisted_2 = { class: "border-r border-[#EAECF0] w-[320px] md:w-full md:border-none" }
const _hoisted_3 = { class: "p-4 flex items-center justify-between border-b border-[#EAECF0]" }
const _hoisted_4 = { class: "flex items-center gap-2 text-base text-[#344054] font-medium" }
const _hoisted_5 = { class: "h-11 p-1 bg-[#f8f9fb] rounded-[10px] border border-[#eaecf0] items-center gap-1 inline-flex" }
const _hoisted_6 = { class: "p-4" }
const _hoisted_7 = { class: "text-[#344053] text-base font-medium leading-normal mb-4" }
const _hoisted_8 = { class: "flex-1" }
const _hoisted_9 = { class: "px-4 mb-4 flex justify-between items-center" }
const _hoisted_10 = { class: "items-center gap-1.5 flex cursor-not-allowed" }
const _hoisted_11 = { class: "text-[#98a1b2] text-xs leading-[18px]" }
const _hoisted_12 = { class: "text-[#475466] text-xs leading-[18px]" }
const _hoisted_13 = { class: "flex-1 overflow-hidden" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_SvgIcon, { name: "playground" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('endpoints.playground.title')), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(["px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer hover:bg-white", 
              $setup.playgroundMode === 'test' ? 'bg-white shadow' : 'bg-[#f8f9fb]'
            ]),
              onClick: _cache[0] || (_cache[0] = $event => ($setup.changePlaygroundMode('test')))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["text-[#344053] text-sm font-medium", 
                $setup.playgroundMode === 'test' ? 'text-[#344053]' : 'text-[#667084]'
              ])
              }, " Test ", 2 /* CLASS */)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["px-3 py-2 rounded-md justify-center items-center gap-2 flex cursor-pointer hover:bg-white", 
              $setup.playgroundMode === 'api' ? 'bg-white shadow' : 'bg-[#f8f9fb]'
            ]),
              onClick: _cache[1] || (_cache[1] = $event => ($setup.changePlaygroundMode('api')))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["text-[#667084] text-sm font-medium", 
                $setup.playgroundMode === 'api' ? 'text-[#344053]' : 'text-[#667084]'
              ])
              }, " API ", 2 /* CLASS */)
            ], 2 /* CLASS */)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('endpoints.playground.parameters')), 1 /* TEXT */),
          _createVNode(_component_el_form, {
            model: $setup.form,
            "label-width": "auto",
            "label-position": "top",
            class: "max-w-[288px] md:max-w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "Top P" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $setup.form.top_p,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.top_p) = $event)),
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.topPRange, (top_p) => {
                        return _createVNode(_component_el_option, {
                          key: top_p,
                          label: top_p,
                          value: top_p
                        }, null, 8 /* PROPS */, ["label", "value"])
                      }), 64 /* STABLE_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_form_item, { label: "Top K" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $setup.form.top_k,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.top_k) = $event)),
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.topKRange, (top_k) => {
                        return _createVNode(_component_el_option, {
                          key: top_k,
                          label: top_k,
                          value: top_k
                        }, null, 8 /* PROPS */, ["label", "value"])
                      }), 64 /* STABLE_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_form_item, { label: "Temperature" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $setup.form.temperature,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.form.temperature) = $event)),
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.temperatureRange, (temperature) => {
                        return _createVNode(_component_el_option, {
                          key: temperature,
                          label: temperature,
                          value: temperature
                        }, null, 8 /* PROPS */, ["label", "value"])
                      }), 64 /* STABLE_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_form_item, { label: "Repetition Penalty" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $setup.form.repetition_penalty,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.form.repetition_penalty) = $event)),
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.repetitionPenaltyRange, (repetition_penalty) => {
                        return _createVNode(_component_el_option, {
                          key: repetition_penalty,
                          label: repetition_penalty,
                          value: repetition_penalty
                        }, null, 8 /* PROPS */, ["label", "value"])
                      }), 64 /* STABLE_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_form_item, { label: "Max Tokens" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $setup.form.max_tokens,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.form.max_tokens) = $event)),
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.maxTokensRange, (max_tokens) => {
                        return _createVNode(_component_el_option, {
                          key: max_tokens,
                          label: max_tokens,
                          value: max_tokens
                        }, null, 8 /* PROPS */, ["label", "value"])
                      }), 64 /* STABLE_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["model"])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_8, [
        _createVNode($setup["TestEndpoint"], {
          appEndpoint: $props.appEndpoint,
          modelId: $props.modelId,
          form: $setup.form
        }, null, 8 /* PROPS */, ["appEndpoint", "modelId", "form"]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_SvgIcon, { name: "json" }),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('endpoints.playground.json')), 1 /* TEXT */)
          ]),
          _createElementVNode("div", {
            class: "items-center gap-1.5 flex cursor-pointer",
            onClick: _cache[7] || (_cache[7] = $event => ($setup.dialogVisible = true))
          }, [
            _createVNode(_component_SvgIcon, { name: "fullscreen" }),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('endpoints.playground.maximum')), 1 /* TEXT */)
          ])
        ])
      ], 512 /* NEED_PATCH */), [
        [_vShow, $setup.playgroundMode === 'test']
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_13, [
        _createVNode($setup["ApiExample"], {
          appEndpoint: $props.appEndpoint,
          modelId: $props.modelId,
          form: $setup.form,
          private: $props.private
        }, null, 8 /* PROPS */, ["appEndpoint", "modelId", "form", "private"])
      ], 512 /* NEED_PATCH */), [
        [_vShow, $setup.playgroundMode === 'api']
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($setup.dialogVisible) = $event)),
      fullscreen: "",
      "append-to-body": ""
    }, {
      default: _withCtx(() => [
        _createVNode($setup["TestEndpoint"], {
          appEndpoint: $props.appEndpoint,
          modelId: $props.modelId,
          form: $setup.form
        }, null, 8 /* PROPS */, ["appEndpoint", "modelId", "form"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}