import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-[96px] lg:pb-[64px] w-full bg-white" }
const _hoisted_2 = { class: "flex lg:flex-col justify-between max-w-[1280px] m-auto px-[32px]" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex items-center justify-center will-change-contents w-[130px] mb-[12px] h-[28px] ml-[8px] border border-[#99AFFF] rounded-[16px] text-[14px] text-[#223B99] bg-[#CED8FF]" }
const _hoisted_5 = { class: "font-medium leading-[44px] md:leading-[38px] text-[36px] md:text-[30px] mb-[20px]" }
const _hoisted_6 = { class: "font-light leading-[30px] md:leading-[28px] text-[20px] md:text-[18px] text-[#475467] mb-[32px]" }
const _hoisted_7 = { class: "flex md:flex-col gap-[12px]" }
const _hoisted_8 = {
  href: "https://opencsg.com/docs",
  target: "_blank",
  class: "flex justify-center cursor-pointer md:w-[343px] mb-[48px] md:mb-[12px] py-[16px] px-[22px] text-[18px] leading-[28px] border rounded-[8px]"
}
const _hoisted_9 = {
  href: "/login",
  class: "flex cursor-pointer justify-center md:w-[343px] mb-[48px] py-[16px] px-[22px] text-[18px] leading-[28px] text-[#FFF] bg-[#3250BD] border border-[#3250BD] rounded-[8px]"
}
const _hoisted_10 = { class: "w-[560px] lg:w-auto lg:h-auto h-[236px]" }

export function render(_ctx, _cache) {
  const _component_el_image = _resolveComponent("el-image")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('csghub.serve.recommend')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('csghub.serve.headline')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('csghub.serve.desc')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t("csghub.serve.doc")), 1 /* TEXT */),
          _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t("csghub.serve.register")), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_el_image, {
          src: "/images/csghub/serve.png",
          "preview-src-list": ['/images/csghub/serve.png']
        })
      ])
    ])
  ]))
}