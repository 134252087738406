import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-[#F9FAFB]" }
const _hoisted_2 = { class: "max-w-[1280px] m-auto py-24 px-8" }
const _hoisted_3 = { class: "text-[36px] leading-[44px] md:text-[30px] md:leading-[38px] mb-5" }
const _hoisted_4 = { class: "text-[20px] text-[#475467] leading-[30px] md:text-[18px] md:leading-[28px] font-light mb-16" }
const _hoisted_5 = { class: "grid grid-cols-3 gap-[32px] md:grid-cols-1" }
const _hoisted_6 = { class: "px-[8px] py-[8px] rounded-[40px] bg-[#ACBDFF] border-[8px] border-[#CED8FF]" }
const _hoisted_7 = { class: "flex flex-col gap-[8px]" }
const _hoisted_8 = { class: "text-[20px] text-[#101828] leading-[30px] md:text-[18px] md:leading-[28px]" }
const _hoisted_9 = { class: "text-[16px] text-[#475467] leading-[24px] font-light" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('starShip.value.title')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('starShip.value.desc')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex flex-col items-start gap-[20px] md:mb-[0px] mb-16",
            key: item.id
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_SvgIcon, {
                name: item.icon
              }, null, 8 /* PROPS */, ["name"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('starShip.value.' + item.title)), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('starShip.value.' + item.desc)), 1 /* TEXT */)
            ])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}