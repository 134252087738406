import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.status === 'Running')
      ? (_openBlock(), _createBlock($setup["RunningStatus"], {
          key: 0,
          status: $props.status
        }, null, 8 /* PROPS */, ["status"]))
      : _createCommentVNode("v-if", true),
    ($props.status === 'Stopped')
      ? (_openBlock(), _createBlock($setup["StoppedStatus"], {
          key: 1,
          status: $props.status
        }, null, 8 /* PROPS */, ["status"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}