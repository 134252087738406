import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full xl:flex-col md:pl-0 flex flex-wrap gap-2 mb-2"
}
const _hoisted_2 = {
  key: 1,
  class: "w-full xl:flex-col xl:w-full flex flex-wrap justify-between gap-y-2 mb-2"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.reposData.repository_type === 'space')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["ApplicationSpaceItem"], {
          repo: $setup.theReposData,
          "repo-type": $props.reposData.repository_type,
          isCollection: true
        }, null, 8 /* PROPS */, ["repo", "repo-type"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode($setup["RepoItem"], {
          repo: $setup.theReposData,
          "repo-type": $props.reposData.repository_type,
          isCollection: true
        }, null, 8 /* PROPS */, ["repo", "repo-type"])
      ]))
}