import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sm:w-[100%] sm:mt-[36px] pt-4 pb-8 px-2" }
const _hoisted_2 = { class: "text-[20px] text-[#303133] flex items-center gap-[8px]" }
const _hoisted_3 = { class: "text-[#98A2B3] text-[16px] leading-[24px]" }
const _hoisted_4 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_5 = { class: "flex gap-2" }
const _hoisted_6 = { class: "flex w-full gap-2 group" }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_8 = { class: "mt-[32px]" }
const _hoisted_9 = { class: "text-[20px] text-[#303133] flex items-center gap-[8px]" }
const _hoisted_10 = { class: "text-[#98A2B3] text-[16px] leading-[24px]" }
const _hoisted_11 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_12 = { class: "flex gap-2" }
const _hoisted_13 = { class: "flex w-full gap-2 group" }
const _hoisted_14 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_15 = { class: "mt-[32px]" }
const _hoisted_16 = { class: "text-[20px] text-[#303133] flex items-center gap-[8px]" }
const _hoisted_17 = { class: "text-[#98A2B3] text-[16px] leading-[24px]" }
const _hoisted_18 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_19 = { class: "flex gap-2" }
const _hoisted_20 = { class: "flex w-full gap-2 group" }
const _hoisted_21 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_22 = { class: "mt-[32px]" }
const _hoisted_23 = { class: "text-[20px] text-[#303133] flex items-center gap-[8px]" }
const _hoisted_24 = { class: "text-[#98A2B3] text-[16px] leading-[24px]" }
const _hoisted_25 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_26 = { class: "flex gap-2" }
const _hoisted_27 = { class: "flex w-full gap-2 group" }
const _hoisted_28 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" models "),
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_2, [
        _createVNode(_component_SvgIcon, {
          name: "models",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('organization.model')), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_3, _toDisplayString($setup.models.length), 1 /* TEXT */)
      ]),
      ($setup.hasModels)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.models, (model) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode($setup["RepoItem"], {
                    repo: model,
                    "repo-type": "model"
                  }, null, 8 /* PROPS */, ["repo"]),
                  ($props.canManage)
                    ? (_openBlock(), _createBlock(_component_SvgIcon, {
                        key: 0,
                        class: "cursor-pointer hidden group-hover:block",
                        onClick: $event => ($setup.removeRepo(model.id)),
                        name: "trash"
                      }, null, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true)
                ])
              ]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('all.noData')), 1 /* TEXT */))
    ]),
    _createCommentVNode(" datasets "),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("h3", _hoisted_9, [
        _createVNode(_component_SvgIcon, {
          name: "datasets",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('organization.dataset')), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_10, _toDisplayString($setup.datasets.length), 1 /* TEXT */)
      ]),
      ($setup.hasDatasets)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.datasets, (dataset) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode($setup["RepoItem"], {
                    repo: dataset,
                    "repo-type": "dataset"
                  }, null, 8 /* PROPS */, ["repo"]),
                  ($props.canManage)
                    ? (_openBlock(), _createBlock(_component_SvgIcon, {
                        key: 0,
                        class: "cursor-pointer hidden group-hover:block",
                        onClick: $event => ($setup.removeRepo(dataset.id)),
                        name: "trash"
                      }, null, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true)
                ])
              ]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.$t('all.noData')), 1 /* TEXT */))
    ]),
    _createCommentVNode(" code repo "),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("h3", _hoisted_16, [
        _createVNode(_component_SvgIcon, {
          name: "codes",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('organization.code')), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_17, _toDisplayString($setup.codes.length), 1 /* TEXT */)
      ]),
      ($setup.hasCodes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.codes, (code) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode($setup["RepoItem"], {
                    repo: code,
                    "repo-type": "code"
                  }, null, 8 /* PROPS */, ["repo"]),
                  ($props.canManage)
                    ? (_openBlock(), _createBlock(_component_SvgIcon, {
                        key: 0,
                        class: "cursor-pointer hidden group-hover:block",
                        onClick: $event => ($setup.removeRepo(code.id)),
                        name: "trash"
                      }, null, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true)
                ])
              ]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.$t('all.noData')), 1 /* TEXT */))
    ]),
    _createCommentVNode(" spaces "),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("h3", _hoisted_23, [
        _createVNode(_component_SvgIcon, {
          name: "spaces",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('organization.space')), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_24, _toDisplayString($setup.spaces.length), 1 /* TEXT */)
      ]),
      ($setup.hasSpaces)
        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.spaces, (space) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createVNode($setup["ApplicationSpaceItem"], {
                    repo: space,
                    "repo-type": "space",
                    class: "flex-grow"
                  }, null, 8 /* PROPS */, ["repo"]),
                  ($props.canManage)
                    ? (_openBlock(), _createBlock(_component_SvgIcon, {
                        key: 0,
                        class: "cursor-pointer hidden group-hover:block",
                        onClick: $event => ($setup.removeRepo(space.id)),
                        name: "trash"
                      }, null, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true)
                ])
              ]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(_ctx.$t('all.noData')), 1 /* TEXT */))
    ])
  ]))
}