import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-[20px] bg-white rounded my-6 text-[#606266]" }
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('space.Desc1')), 1 /* TEXT */),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('space.Desc2')) + " ", 1 /* TEXT */),
      _createElementVNode("a", {
        href: $props.starChainAddress,
        target: "_blank",
        class: "text-[#1a8b7b] underline"
      }, " StarChain", 8 /* PROPS */, _hoisted_2),
      _createTextVNode(_toDisplayString(_ctx.$t('space.Desc3')), 1 /* TEXT */)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('space.Desc4')), 1 /* TEXT */)
  ]))
}