import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: $setup.activeName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.activeName) = $event)),
      beforeLeave: $setup.handleTabLeave,
      onTabClick: $setup.handleTabClick
    }, {
      default: _withCtx(() => [
        _createCommentVNode(" repo/endpoint summary "),
        _createVNode(_component_el_tab_pane, {
          label: $setup.summaryLabel,
          name: "summary",
          lazy: ""
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "summary")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" repo files "),
        ($setup.showFiles)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: _ctx.$t('all.files'),
              name: "files",
              lazy: ""
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "files")
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" repo community "),
        ($props.repoType !== 'endpoint' && !!$props.localRepoId)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 1,
              label: _ctx.$t('all.community'),
              name: "community",
              class: "min-h-[300px]",
              lazy: ""
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "community")
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" endpoint logs "),
        ($props.repoType === 'endpoint')
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 2,
              label: _ctx.$t('all.logs'),
              name: "logs",
              class: "min-h-[300px]",
              lazy: ""
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "logs")
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" billing "),
        (($props.repoType === 'endpoint' || $props.repoType === 'space') && $props.settingsVisibility)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 3,
              label: _ctx.$t('billing.billing'),
              name: "billing",
              class: "min-h-[300px]",
              lazy: ""
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "billing")
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" repo settings "),
        ($props.settingsVisibility)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 4,
              label: _ctx.$t('all.settings'),
              name: "settings",
              class: "min-h-[300px]",
              lazy: ""
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "settings")
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}