import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f189b294"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rounded-[8px]" }
const _hoisted_2 = { class: "flex justify-between items-center mb-8" }
const _hoisted_3 = { class: "text-[30px] leading-[38px]" }
const _hoisted_4 = { class: "flex flex-col gap-6 mb-8" }
const _hoisted_5 = { class: "flex flex-col w-[320px] rounded-lg p-6 border border-[#EAECF0] gap-6 text-[16px] leading-[24px]" }
const _hoisted_6 = { class: "flex gap-4 items-center" }
const _hoisted_7 = { class: "bg-[#3250BD] p-3 rounded-lg" }
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "flex justify-between mb-4" }
const _hoisted_10 = { class: "flex gap-2 text-[20px] leading-[30px] text-[#344054]" }
const _hoisted_11 = { class: "text-[14px] leading-[20px] text-[#475467]" }
const _hoisted_12 = { class: "mt-[12px] mb-[16px] flex justify-center" }
const _hoisted_13 = { class: "flex justify-between mb-4" }
const _hoisted_14 = { class: "flex gap-2 text-[20px] leading-[30px] text-[#344054]" }
const _hoisted_15 = { class: "text-[14px] leading-[20px] text-[#475467]" }
const _hoisted_16 = { class: "mt-[12px] mb-[16px] flex justify-center" }
const _hoisted_17 = { class: "flex justify-between mb-4" }
const _hoisted_18 = { class: "flex gap-2 text-[20px] leading-[30px] text-[#344054]" }
const _hoisted_19 = { class: "text-[14px] leading-[20px] text-[#475467]" }
const _hoisted_20 = { class: "mt-[12px] mb-[16px] flex justify-center" }
const _hoisted_21 = { class: "flex justify-between mb-4" }
const _hoisted_22 = { class: "flex gap-2 text-[20px] leading-[30px] text-[#344054]" }
const _hoisted_23 = { class: "text-[14px] leading-[20px] text-[#475467]" }
const _hoisted_24 = { class: "mt-[12px] mb-[16px] flex justify-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" billing title "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('billing.title')), 1 /* TEXT */),
      _createVNode(_component_el_date_picker, {
        modelValue: $setup.selectedMonth,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.selectedMonth) = $event)),
        onChange: $setup.dateChange,
        type: "month",
        placeholder: "Pick",
        "disabled-date": $setup.disabledDate,
        style: {"width":"120px"}
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createCommentVNode(" balance "),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_SvgIcon, { name: "billing_wallet" })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('billing.balance')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", {
          class: "text-[36px] leading-[44px] overflow-hidden text-ellipsis whitespace-nowrap",
          title: $setup.balance
        }, " ¥ " + _toDisplayString($setup.balance), 9 /* TEXT, PROPS */, _hoisted_8)
      ])
    ]),
    _createCommentVNode(" space billing "),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_SvgIcon, {
          name: "spaces",
          width: "18",
          height: "18"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('spaces.title')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('billing.total')) + "：¥ " + _toDisplayString($setup.spaceTotalPrice), 1 /* TEXT */)
    ]),
    _createVNode($setup["BillingTable"], {
      billings: $setup.spaceBillings,
      selectedMonth: $setup.selectedMonth,
      type: "space"
    }, null, 8 /* PROPS */, ["billings", "selectedMonth"]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode($setup["CsgPagination"], {
        perPage: $setup.perPage,
        currentPage: $setup.spaceCurrentPage,
        onCurrentChange: $setup.fetchSpace,
        total: $setup.spaceTotalBillings
      }, null, 8 /* PROPS */, ["perPage", "currentPage", "total"])
    ]),
    _createCommentVNode(" inference instances "),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_SvgIcon, {
          name: "endpoint",
          width: "18",
          height: "18"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('billing.inference')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('billing.total')) + "：¥ " + _toDisplayString($setup.inferenceTotalPrice), 1 /* TEXT */)
    ]),
    _createVNode($setup["BillingTable"], {
      billings: $setup.inferenceBillings,
      selectedMonth: $setup.selectedMonth,
      type: "inference"
    }, null, 8 /* PROPS */, ["billings", "selectedMonth"]),
    _createElementVNode("div", _hoisted_16, [
      _createVNode($setup["CsgPagination"], {
        perPage: $setup.perPage,
        currentPage: $setup.inferenceCurrentPage,
        onCurrentChange: $setup.fetchInference,
        total: $setup.inferenceTotalBillings
      }, null, 8 /* PROPS */, ["perPage", "currentPage", "total"])
    ]),
    _createCommentVNode(" finetune "),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_SvgIcon, {
          name: "profile_finetune",
          width: "18",
          height: "18"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('billing.finetune')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('billing.total')) + "：¥ " + _toDisplayString($setup.finetuneTotalPrice), 1 /* TEXT */)
    ]),
    _createVNode($setup["BillingTable"], {
      billings: $setup.finetuneBillings,
      selectedMonth: $setup.selectedMonth,
      type: "finetune"
    }, null, 8 /* PROPS */, ["billings", "selectedMonth"]),
    _createElementVNode("div", _hoisted_20, [
      _createVNode($setup["CsgPagination"], {
        perPage: $setup.perPage,
        currentPage: $setup.finetuneCurrentPage,
        onCurrentChange: $setup.fetchFinetune,
        total: $setup.finetuneTotalBillings
      }, null, 8 /* PROPS */, ["perPage", "currentPage", "total"])
    ]),
    _createCommentVNode(" starship "),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_SvgIcon, {
          name: "billing_starship",
          width: "18",
          height: "18"
        }),
        _createTextVNode(" Starship ")
      ]),
      _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('billing.total')) + "：¥ " + _toDisplayString($setup.starshipTotalPrice), 1 /* TEXT */)
    ]),
    _createVNode($setup["BillingTable"], {
      billings: $setup.starshipBillings,
      selectedMonth: $setup.selectedMonth,
      type: "starship"
    }, null, 8 /* PROPS */, ["billings", "selectedMonth"]),
    _createElementVNode("div", _hoisted_24, [
      _createVNode($setup["CsgPagination"], {
        perPage: $setup.perPage,
        currentPage: $setup.starshipCurrentPage,
        onCurrentChange: $setup.fetchStarship,
        total: $setup.starshipTotalBillings
      }, null, 8 /* PROPS */, ["perPage", "currentPage", "total"])
    ])
  ]))
}