import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")
  const _component_el_descriptions = _resolveComponent("el-descriptions")
  const _component_el_button = _resolveComponent("el-button")
  const _component_router_link = _resolveComponent("router-link")
  const _component_el_card = _resolveComponent("el-card")

  return (_openBlock(), _createBlock(_component_el_card, null, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: `/new_admin/eai_articles/${$setup.eaiDevice.id}/edit`
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, { class: "w-[100px]" }, {
              default: _withCtx(() => [
                _createTextVNode("编辑")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_descriptions, {
        title: "具身智能文章",
        column: 2,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "标题" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.eaiDevice.title), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "链接" }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: $setup.eaiDevice.link,
                target: "_blank"
              }, _toDisplayString($setup.eaiDevice.link), 9 /* TEXT, PROPS */, _hoisted_1)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "描述",
            span: 2
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.eaiDevice.description), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "封面" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: $setup.eaiDevice.cover_url,
                alt: "cover",
                class: "h-[200px] object-cover"
              }, null, 8 /* PROPS */, _hoisted_2)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}