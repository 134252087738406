export const footer = {
  "about": "关于我们",
  "news": "新闻动态",
  "docs": "文档中心",
  "join": "加入我们",
  "contact": "联系我们",
  "policy": "用户协议 & 隐私政策",
  "illegal": "违法和不良信息举报中心",
  "police": "京公网安备11011202004415号 ",
  "company": "公司介绍",
  "partner": "商务合作",
  "resume": "简历投递",
  "pr": "媒体合作",
  "ir": "投资关系",
  "cookies": "Cookies政策",
  "publish": "©2024 OpenCSG.com版权所有",
  "ICP": "京ICP备2023006263号-1",
  "wx": "微信公众号",
  "follow":"关注OpenCSG",
  "followText":"关注OpenCSG公众号，随时随地掌握实时资讯"
}
