import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border border-[#DCDFE6] rounded-[8px] my-[32px] md:my-0 md:border-none px-[24px] py-[24px]" }
const _hoisted_2 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_3 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_4 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_5 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_6 = { class: "flex flex-col gap-[6px]" }
const _hoisted_7 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_8 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_9 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_10 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_11 = { class: "flex flex-col gap-[6px]" }
const _hoisted_12 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_13 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_14 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_15 = { class: "flex flex-col gap-[6px]" }
const _hoisted_16 = { class: "flex flex-col gap-[6px]" }
const _hoisted_17 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_18 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_19 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_20 = { class: "flex flex-col gap-[6px]" }
const _hoisted_21 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_22 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_23 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_24 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_25 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_26 = { class: "flex flex-col gap-[6px]" }
const _hoisted_27 = { class: "text-[#344054] text-[14px]" }
const _hoisted_28 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_29 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_30 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_31 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_32 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_33 = { class: "flex flex-col gap-[6px]" }
const _hoisted_34 = { class: "text-[#344054] text-[14px]" }
const _hoisted_35 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_36 = { class: "w-[380px] sm:w-full flex flex-col gap-[6px]" }
const _hoisted_37 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_38 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_39 = { class: "text-black font-medium break-words" }
const _hoisted_40 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_41 = { class: "text-black font-medium break-words" }
const _hoisted_42 = { class: "flex flex-col gap-[8px]" }
const _hoisted_43 = { class: "text-[#344054] text-[14px]" }
const _hoisted_44 = { class: "flex" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_divider = _resolveComponent("el-divider")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 英文名称 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('finetune.detail.settings.enName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('finetune.detail.settings.enNameTip1')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_input, {
          value: $setup.props.finetuneName,
          disabled: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["value"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 别名 "),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('finetune.detail.settings.cnName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('finetune.detail.settings.cnNameTip1')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_el_input, {
          modelValue: $setup.cnName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.cnName) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 暂停 "),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('endpoints.settings.stopEndpoint')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = $event => ($setup.changeStatus('stop'))),
            class: "w-[100px]",
            disabled: !$setup.initialized || $setup.isStopped
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('endpoints.settings.stop')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"])
        ])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 重启 "),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('endpoints.settings.restartEndpoint')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_el_button, {
          onClick: _cache[2] || (_cache[2] = $event => ($setup.changeStatus('start'))),
          class: "w-[100px]",
          disabled: $setup.notInitialized
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('endpoints.settings.restart')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 区域 "),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('finetune.detail.settings.region')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('finetune.detail.settings.regionTip1')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('finetune.detail.settings.regionTip2')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.$t('finetune.detail.settings.regionTip3')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.currentCid,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.currentCid) = $event)),
          placeholder: _ctx.$t('all.select'),
          size: "large",
          class: "!w-[512px] sm:!w-full",
          disabled: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.finetuneClusters, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.cluster_id,
                label: item.region,
                value: item.cluster_id
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "placeholder"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 空间云资源 "),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('finetune.detail.settings.resources')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('finetune.detail.settings.resourcesTip1')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t('finetune.detail.settings.resourcesTip2')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.$t('finetune.detail.settings.resourcesTip3')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.currentResource,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.currentResource) = $event)),
          placeholder: _ctx.$t('all.select'),
          size: "large",
          class: "!w-[512px] sm:!w-full",
          disabled: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cloudResources, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.name,
                label: item.name,
                value: item.resources,
                disabled: !item.is_available
              }, null, 8 /* PROPS */, ["label", "value", "disabled"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "placeholder"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 删除 "),
    _createElementVNode("div", _hoisted_35, [
      _createElementVNode("div", _hoisted_36, [
        _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t('finetune.detail.settings.delete')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_38, [
          _createTextVNode(_toDisplayString(_ctx.$t('finetune.detail.settings.deleteTip1')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_39, _toDisplayString($setup.props.modelId), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('finetune.detail.settings.deleteTip2')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_40, [
          _createTextVNode(_toDisplayString(_ctx.$t('all.enterPls')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_41, _toDisplayString(`${$props.finetuneName}/${$props.finetuneId}`), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('all.sureDel')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_42, [
        _createElementVNode("p", _hoisted_43, _toDisplayString(_ctx.$t('finetune.detail.settings.finetuneName')), 1 /* TEXT */),
        _createVNode(_component_el_input, {
          modelValue: $setup.delDesc,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.delDesc) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("div", {
            id: "confirmDelete",
            onClick: $setup.clickDelete,
            class: _normalizeClass(["text-[#98A2B3] py-[8px] px-[12px] text-[14px] leading-[20px] rounded-[8px]", 
              $setup.delDesc === `${$props.finetuneName}/${$props.finetuneId}`
                ? 'bg-[#D92D20] text-[#FFFFFF] cursor-pointer active:shadow-box active:space-y-0 active:space-x-0 active:ring-4 active:ring-red-400 active:ring-opacity-25 active:bg-[#D92D20] hover:text-white'
                : 'bg-[#F2F4F7]'
            ]),
            onMouseover: $setup.handleMouseOver,
            onMouseleave: $setup.handleMouseLeave
          }, _toDisplayString(_ctx.$t('endpoints.settings.confirmDel')), 35 /* TEXT, CLASS, NEED_HYDRATION */)
        ])
      ])
    ])
  ]))
}