import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border border-[#DCDFE6] rounded-[8px] my-[32px] md:my-0 md:border-none px-[24px] py-[24px]" }
const _hoisted_2 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_3 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_4 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_5 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_6 = { class: "flex flex-col gap-[6px]" }
const _hoisted_7 = { class: "text-[#344054] text-[14px]" }
const _hoisted_8 = { class: "w-[512px] sm:w-full rounded-[8px] bg-[#F9FAFB] px-[14px] py-[10px] border" }
const _hoisted_9 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_10 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_11 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_12 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_13 = { class: "flex flex-col gap-[6px]" }
const _hoisted_14 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_15 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_16 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_17 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_18 = { class: "flex flex-col gap-[6px]" }
const _hoisted_19 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_20 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_21 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_22 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_23 = { class: "text-black font-semibold" }
const _hoisted_24 = { class: "flex flex-col gap-[6px]" }
const _hoisted_25 = { class: "text-[#344054] text-[14px]" }
const _hoisted_26 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_27 = { class: "w-[380px] sm:w-full flex flex-col gap-[6px]" }
const _hoisted_28 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_29 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_30 = { class: "text-black font-medium" }
const _hoisted_31 = { class: "text-black font-medium break-words" }
const _hoisted_32 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_33 = { class: "text-black font-medium break-words" }
const _hoisted_34 = { class: "flex flex-col gap-[8px]" }
const _hoisted_35 = { class: "text-[#344054] text-[14px]" }
const _hoisted_36 = { class: "flex" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 展示英文名 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('codes.codeName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('codes.codeNickName')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('codes.namespaceCodeName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_8, _toDisplayString($data.codePath), 1 /* TEXT */)
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 更新别名 "),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('codes.codeNickName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('codes.edit.tips')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_el_input, {
          modelValue: $data.theCodeNickname,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.theCodeNickname) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_el_button, {
          onClick: $options.updateNickname,
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 更新简介 "),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('codes.codeDesc')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('codes.edit.tips2')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_el_input, {
          modelValue: $data.theCodeDesc,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.theCodeDesc) = $event)),
          clearable: "",
          size: "large",
          type: "textarea",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_el_button, {
          onClick: $options.updateCodeDesc,
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 修改可见性 "),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('codes.edit.changeVisibility')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_22, [
          _createTextVNode(_toDisplayString(_ctx.$t('codes.edit.statusText')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_23, "【" + _toDisplayString(_ctx.isPrivate ? this.$t('all.private') : this.$t('all.public')) + "】", 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('codes.edit.status')) + "。" + _toDisplayString(_ctx.isPrivate
              ? this.$t('codes.edit.privateVis')
              : this.$t('codes.edit.publicVis')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t('codes.edit.codeVisibility')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $options.visibilityName,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($options.visibilityName) = $event)),
          onChange: $options.changeVisibility,
          placeholder: "Select",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.options, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.label,
                value: item.value
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "onChange"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 删除 "),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('codes.edit.delCode')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_29, [
          _createTextVNode(_toDisplayString(_ctx.$t('codes.edit.delTips')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t('all.canNot')), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('codes.edit.delTips2')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_31, _toDisplayString($props.path), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('codes.edit.delTips3')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_32, [
          _createTextVNode(_toDisplayString(_ctx.$t('all.enterPls')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_33, _toDisplayString($props.path), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('all.sureDel')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.$t('codes.codeName')), 1 /* TEXT */),
        _createVNode(_component_el_input, {
          modelValue: $data.delDesc,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.delDesc) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_36, [
          _createElementVNode("div", {
            id: "confirmDelete",
            onClick: _cache[4] || (_cache[4] = (...args) => ($options.clickDelete && $options.clickDelete(...args))),
            class: _normalizeClass(["text-[#98A2B3] py-[8px] px-[12px] text-[14px] leading-[20px] rounded-[8px]", 
              $data.delDesc === $data.codePath
                ? 'bg-[#D92D20] text-[#FFFFFF] cursor-pointer active:shadow-box active:space-y-0 active:space-x-0 active:ring-4 active:ring-red-400 active:ring-opacity-25 active:bg-[#D92D20] hover:text-white'
                : 'bg-[#F2F4F7]'
            ]),
            onMouseover: _cache[5] || (_cache[5] = (...args) => ($options.handleMouseOver && $options.handleMouseOver(...args))),
            onMouseleave: _cache[6] || (_cache[6] = (...args) => ($options.handleMouseLeave && $options.handleMouseLeave(...args)))
          }, _toDisplayString(_ctx.$t('codes.edit.confirmDel')), 35 /* TEXT, CLASS, NEED_HYDRATION */)
        ])
      ])
    ])
  ]))
}