import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "h-[50vh] py-[32px] flex flex-col items-center justify-center sm:px-[20px]" }
const _hoisted_2 = {
  key: 0,
  class: "text-[#6B7280] text-[16px]"
}
const _hoisted_3 = {
  key: 1,
  class: "text-[#6B7280] text-[16px]"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (['Building','Deploying','Startup'].includes($props.appStatus))
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('application_spaces.buildDesc')), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (['DeployFailed','BuildingFailed','RuntimeError'].includes($props.appStatus))
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t('application_spaces.errorDesc')) + " ", 1 /* TEXT */),
          ($props.canWrite)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "text-[#223B99] font-[400] text-[12px] mt-[12px] cursor-pointer",
                onClick: $setup.showLogs
              }, _toDisplayString(_ctx.$t('application_spaces.errorPage.showErrorLogs')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}