import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-[14px] flex items-center justify-between rounded-[8px] border border-gray-300 mb-6" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      rows: "1",
      placeholder: _ctx.$t('comment.createComment'),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.newCommentContent) = $event)),
      class: "flex-1 outline-none mr-4 text-sm text-[#606266] h-[40px]"
    }, null, 8 /* PROPS */, _hoisted_2), [
      [_vModelText, $setup.newCommentContent]
    ]),
    _createElementVNode("button", {
      disabled: $setup.newCommentContent.trim().length === 0,
      class: _normalizeClass([{ 'bg-[#409EFF]': $setup.newCommentContent.trim().length > 0, 'bg-[#9FCEFF]': $setup.newCommentContent.trim().length === 0 }, "flex justify-center items-center rounded-[4px] w-[88px] h-[32px] text-white text-sm font-medium"]),
      onClick: $setup.createComment
    }, _toDisplayString(_ctx.$t('comment.createComment')), 11 /* TEXT, CLASS, PROPS */, _hoisted_3)
  ]))
}