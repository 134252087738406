import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pt-[96px] text-center w-full" }
const _hoisted_2 = { class: "max-w-[1280px] m-auto" }
const _hoisted_3 = { class: "flex flex-col items-center md:px-[20px] mb-[64px] md:mb-[48px] relative" }
const _hoisted_4 = { class: "text-[16px] text-[#223B99] mb-[20px]" }
const _hoisted_5 = { class: "text-[36px] md:text-[30px] leading-[44px] md:leading-[38px] text-[#101828]" }
const _hoisted_6 = { class: "my-[20px] text-center text-[20px] md:text-[18px] text-[#606266] leading-[30px] md:leading-[28px] font-light" }
const _hoisted_7 = { class: "w-full bg-white pb-[48px] md:pb-0" }
const _hoisted_8 = { class: "max-w-[1280px] m-auto" }
const _hoisted_9 = { class: "gap-[32px] py-[32px] md:py-0 lg:w-auto lg:grid-cols-1 items-start md:mx-0" }
const _hoisted_10 = { class: "flex flex-col items-start gap-[20px] md:px-[24px]" }
const _hoisted_11 = { class: "text-[30px] md:text-[24px] leading-[38px] md:leading-[32px] text-[#101828] mb-4" }
const _hoisted_12 = { class: "text-[18px] md:text-[16px] leading-[28px] md:leading-[24px] font-light text-[#475467]" }
const _hoisted_13 = { class: "flex flex-col gap-[16px] px-[16px] md:px-0" }
const _hoisted_14 = { class: "flex items-center" }
const _hoisted_15 = { class: "ml-[12px] font-light text-[18px] md:text-[16px] text-[#475467]" }
const _hoisted_16 = { class: "grow max-w-[730px] max-h-[620px] lg:rounded-[24px] pr-0 xl:mt-[20px] lg:mt-0 lg:w-full lg:px-0 overflow-hidden" }
const _hoisted_17 = { class: "py-[96px] md:py-[64px] w-full bg-[#F9FAFB]" }
const _hoisted_18 = { class: "max-w-[1280px] m-auto" }
const _hoisted_19 = { class: "px-[32px] flex md:flex-col gap-[64px] md:gap-[48px] mb-[64px] md:mb-0" }
const _hoisted_20 = { class: "flex flex-col items-start max-w-[380px]" }
const _hoisted_21 = { class: "max-w-[301px] mb-[20px] text-[#101828] text-[36px] md:text-[30px] leading-[44px] md:leading-[38px]" }
const _hoisted_22 = { class: "text-[#667085] mb-[20px] text-[18px] leading-[28px] font-light" }
const _hoisted_23 = {
  href: "https://opencsg.com/lead_forms/form/LhkJ4U4Kj9UD_CSGHub_%E5%92%A8%E8%AF%A2",
  target: "_blank",
  class: "cursosr-pointer inline-block py-[16px] px-[22px] text-[18px] leading-[28px] text-[#FFF] bg-[#3250BD] border border-[#3250BD] rounded-[8px]"
}
const _hoisted_24 = { class: "grid grid-cols-2 gap-[32px] md:gap-[16px] md:grid-cols-1" }
const _hoisted_25 = { class: "text-[18px] leading-[28px] text-[#101828] mb-[8px]" }
const _hoisted_26 = { class: "text-[16px] leading-[24px] font-light text-[#475467]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_image = _resolveComponent("el-image")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('csghub.product.introduce')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('csghub.product.headline')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('csghub.product.desc')), 1 /* TEXT */)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cardVersion, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: _normalizeClass(["pl-[32px] md:px-0 flex items-center justify-center lg:flex-col gap-[96px] md:gap-[48px] mb-[64px] md:flex-direction", index % 2 == '1' ? 'flex-row-reverse' : ''])
          }, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_SvgIcon, {
                  name: item.svgName
                }, null, 8 /* PROPS */, ["name"]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(item.title), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(item.enTitle), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.details, (part) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(_component_SvgIcon, {
                        name: "csghub_hook",
                        width: "23",
                        height: "23"
                      }),
                      _createElementVNode("p", _hoisted_15, _toDisplayString(part), 1 /* TEXT */)
                    ]))
                  }), 256 /* UNKEYED_FRAGMENT */))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_el_image, {
                src: item.img,
                "preview-src-list": [item.img]
              }, null, 8 /* PROPS */, ["src", "preview-src-list"])
            ])
          ], 2 /* CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('csghub.product.feature-h')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('csghub.product.feature-p')), 1 /* TEXT */),
            _createElementVNode("a", _hoisted_23, _toDisplayString(_ctx.$t("csghub.product.feature-button")), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_24, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cardFeature, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: "flex flex-col items-start gap-[32px] px-[24px] py-[24px]"
              }, [
                _createVNode(_component_SvgIcon, {
                  name: item.svgName
                }, null, 8 /* PROPS */, ["name"]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_25, _toDisplayString(item.title), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_26, _toDisplayString(item.enTitle), 1 /* TEXT */)
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}