import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa95c887"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-[77px] w-full p-4 bg-white border-b border-[#eaecf0] items-center gap-2 inline-flex rounded-tr-2xl" }
const _hoisted_2 = { class: "text-[#344053] text-base font-medium leading-normal" }
const _hoisted_3 = { class: "p-4" }
const _hoisted_4 = { class: "h-[34px] w-full justify-between items-center inline-flex" }
const _hoisted_5 = { class: "justify-start items-center gap-4 flex" }
const _hoisted_6 = { class: "rounded-xl border border-[#eaecf0] px-4 py-6 mt-4 relative group" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_checkbox = _resolveComponent("el-checkbox")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SvgIcon, { name: "playground_api" }),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('endpoints.playground.api')), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["text-center text-xs font-normal leading-[18px] flex items-center gap-1 px-2 py-[3px] border border-[#cfd4dc] rounded cursor-pointer", 
            $setup.codeExtension === 'py'
              ? 'bg-[#0f1728] text-white'
              : 'bg-[#fff] text-[#344053]'
          ]),
            onClick: _cache[0] || (_cache[0] = $event => ($setup.changeLanguage('py')))
          }, [
            _createVNode(_component_SvgIcon, { name: "python" }),
            _createTextVNode(" Python ")
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["text-center text-xs font-normal leading-[18px] flex items-center gap-1 px-2 py-[3px] border border-[#cfd4dc] rounded cursor-pointer", 
            $setup.codeExtension === 'js'
              ? 'bg-[#0f1728] text-white'
              : 'bg-[#fff] text-[#344053]'
          ]),
            onClick: _cache[1] || (_cache[1] = $event => ($setup.changeLanguage('js')))
          }, [
            _createVNode(_component_SvgIcon, { name: "javascript" }),
            _createTextVNode(" JavaScript ")
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["text-center text-xs font-normal leading-[18px] flex items-center gap-1 px-2 py-[3px] border border-[#cfd4dc] rounded cursor-pointer", 
            $setup.codeExtension === 'bash'
              ? 'bg-[#0f1728] text-white'
              : 'bg-[#fff] text-[#344053]'
          ]),
            onClick: _cache[2] || (_cache[2] = $event => ($setup.changeLanguage('bash')))
          }, [
            _createVNode(_component_SvgIcon, { name: "curl" }),
            _createTextVNode(" cURL ")
          ], 2 /* CLASS */)
        ]),
        _createVNode(_component_el_checkbox, {
          modelValue: $setup.useToken,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.useToken) = $event)),
          label: _ctx.$t('endpoints.playground.useToken'),
          size: "large"
        }, null, 8 /* PROPS */, ["modelValue", "label"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode($setup["CodeViewer"], {
          content: $setup.codeContent,
          extension: $setup.codeExtension
        }, null, 8 /* PROPS */, ["content", "extension"]),
        _createElementVNode("div", {
          class: "absolute bg-white text-xs text-[#344053] right-6 top-6 px-[16px] py-[8px] border rounded-lg shadow cursor-pointer items-center gap-1 hidden group-hover:flex",
          onClick: $setup.copyCode
        }, [
          _createVNode(_component_SvgIcon, { name: "copy" }),
          _createTextVNode(" Copy ")
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}