import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["CreateComment"], {
      onCreateComment: $setup.createComment,
      commentable_type: $props.commentable_type,
      commentable_id: $props.commentable_id
    }, null, 8 /* PROPS */, ["commentable_type", "commentable_id"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.commentListRef, (comment) => {
      return (_openBlock(), _createBlock($setup["CommentItem"], {
        key: comment.id,
        comment: comment,
        current_user_id: $props.current_user_id,
        onDeleteComment: $setup.deleteComment
      }, null, 8 /* PROPS */, ["comment", "current_user_id"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}