<template>
  <el-form
    ref="dataFormRef"
    :model="dataForm"
    :rules="rules"
    class="w-full flex flex-col gap-[14px]"
    label-position="top"
  >
    <el-form-item
      label="标题"
      prop="title"
      class="w-full"
    >
      <el-input v-model="dataForm.title" />
    </el-form-item>
    <el-form-item
      label="描述"
      prop="description"
      class="w-full"
    >
      <el-input
        :rows="3"
        type="textarea"
        v-model="dataForm.description"
      />
    </el-form-item>
    <!-- link -->
    <el-form-item
      label="链接"
      prop="link"
      class="w-full"
    >
      <el-input v-model="dataForm.link" />
    </el-form-item>
    <!-- codes -->
    <el-form-item label="封面图" prop="cover_url">
      <el-upload
        :class="`w-[406px] ${showUpload ? 'h-[200px]' : 'hide'}`"
        :limit="1"
        v-model:file-list="dataForm.cover_image"
        drag
        list-type="picture-card"
        :headers="{ 'X-CSRF-TOKEN': csrf_token }"
        accept="image/png, image/jpeg, image/gif, image/svg+xml"
        :data="{namespace: 'daily_paper'}"
        action="/internal_api/upload"
        :on-progress="handleUploadProgress"
        :on-remove="handleRemoveImage"
        :on-success="handleUploadSuccess"
      >
        <div>
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            <p>
              <span class="text-[#223B99] font-[500]">
                点击
              </span>
              拖拽
            </p>
            <p>SVG, PNG, JPG 或 GIF</p>
          </div>
        </div>
      </el-upload>
    </el-form-item>

    <el-form-item class="w-full">
      <div class="flex justify-end w-full">
        <el-button
          type="primary"
          @click="handleSubmit"
        >
          提交
        </el-button>
      </div>
    </el-form-item>
  </el-form>

</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { eaiDeviceCreate, eaiDeviceUpdate, eaiDeviceShow } from "../../../api/csrf/eai"
  import { ElMessage } from 'element-plus'
  import { useRouter } from 'vue-router'
  const router = useRouter()

  const props = defineProps({
    eai_device: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        cover_image: "",
        link: "",
      }),
    }
  });

  const showUpload = ref(true);
  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const dataForm = ref({});
  const dataFormRef = ref(null);
  const rules = {
    title: [{ required: true, message: "标题必填", trigger: "blur" }],
    description: [{ required: true, message: "描述必填", trigger: "blur" }],
    link: [{ required: true, message: "外链必填", trigger: "blur" }],
    cover_url: [{ required: true, message: "封面必填", trigger: "blur" }],
  };

  const handleUploadSuccess = (res) => {
    showUpload.value = false;
    dataForm.value.cover_url = res.url;
  };

  const handleRemoveImage = () => {
    showUpload.value = true;
    dataForm.value.cover_url = "";
  };

  const handleUploadProgress = () => {
    showUpload.value = false;
  };

  const handleSubmit = () => {
    dataFormRef.value.validate((valid) => {
      if (valid) {
        createOrUpdate();
      } else {
        return false;
      }
    });
  };
  
  const createOrUpdate = () => {
    const data = dataForm.value;

    if (dataForm.value.id) {
      // update
      update(dataForm.value);
    } else {
      // create
      create(dataForm.value);
    }
  };

  const create = (data) => {
    eaiDeviceCreate(data).then((res) => {
      ElMessage({
        message: "创建成功",
        type: "success",
      });
      router.push({path: "/new_admin/eai_devices"});
    });
  };

  const update = (data) => {
    eaiDeviceUpdate(data).then((res) => {
      ElMessage({
        message: "更新成功",
        type: "success",
      });
      router.push(`/new_admin/eai_devices`);
    });
  };

  onMounted(() => {
    const id = router.currentRoute.value.params.id
    if (id) {
      eaiDeviceShow(id).then((res) => {
        const { data } = res
        if (data.cover_url) {
          showUpload.value = false;
          data.cover_image = [{ url: data.cover_url }];
        }
        dataForm.value = data;
      });
    }
  });
</script>

<style>
  .el-form-item {
    margin-bottom: 24px;
  }
  .el-form-item__label {
    margin-bottom: 6px;
  }
  :deep(.el-input__wrapper) {
    border-radius: 8px;
  }
  :deep(.el-textarea__inner) {
    border-radius: 8px;
  }
  :deep(.el-upload--picture-card) {
    border: 0px;
  }
  .hide .el-upload.el-upload--picture-card{
    display: none;
  }
</style>
