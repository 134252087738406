<template>
    <div class="company">
      <div class="bg-[#F9FAFB] pb-40 md:pb-[112px]">
        <div class="mx-auto max-w-[1280px] pt-[96px] md:pt-[64px] md:py-[40px] md:px-[16px] xl:px-[32px]">
          <div class="flex justify-between md:justify-normal gap-[64px] md:flex-col md:gap-[48px]">
            <div>
              <div class="text-[16px] md:text-[14px] md:leading-[20px] leading-[24px] text-[#223B99] mb-[10px]">
                {{ $t('landingPage.company.ContactUs') }}
              </div>
              <div class="text-[36px] md:text-[30px] md:leading-[38px] leading-[44px] text-[#101828] mb-[20px]">
                {{ $t('landingPage.company.ContactTeam') }}
              </div>
              <div class="text-[20px] md:text-[18px] md:leading-[28px] leading-[30px] text-[#475467]">
                {{ $t('landingPage.company.Welcome') }}
              </div>
            </div>
            <div class="flex-col max-w-[390px] mr-[200px] lg:mr-0">
              <div class="flex gap-[16px] mb-[32px]">
                <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5Z" stroke="#3250BD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 22C14 18 20 15.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 15.4183 10 18 12 22Z" stroke="#3250BD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
                <div>
                  <div class="text-[20px] md:text-[18px] md:leading-[28px] leading-[30px] text-[#101828] mb-[10px]">
                    {{ $t('landingPage.company.Beijing') }}
                  </div>
                  <div class="text-[16px] leading-[24px] font-light text-[#475467]">
                    {{ $t('landingPage.company.BeijingSite') }}
                  </div>
                </div>
              </div>
              <div class="flex gap-[16px]">
                <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5Z" stroke="#3250BD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 22C14 18 20 15.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 15.4183 10 18 12 22Z" stroke="#3250BD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
                <div>
                  <div class="text-[20px] md:text-[18px] md:leading-[28px] leading-[30px] text-[#101828] mb-[10px]">
                    {{ $t('landingPage.company.ShangHai') }}
                  </div>
                  <div class="text-[16px] leading-[24px] font-light text-[#475467]">
                    {{ $t('landingPage.company.ShangHaiSite') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="companyTimeLine flex justify-between lg:justify-normal my-[48px] gap-[64px] lg:flex-col lg:gap-[48px]">
            <el-timeline style="max-width: 600px">
              <el-timeline-item
                placement="top"
                v-for="(activity, index) in activitiesL"
                :key="index"
                :timestamp="activity.timestamp"
              >
                <div v-for="content in activity.contentList" class="flex items-start">
                  <div class="mr-[8px] pt-2">
                    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="2" cy="2" r="2" fill="#475467"/></svg>
                  </div>
                  {{ content }}
                </div>
              </el-timeline-item>
            </el-timeline>
            <el-timeline style="max-width: 600px">
              <el-timeline-item
                placement="top"
                v-for="(activity, index) in activitiesR"
                :key="index"
                :timestamp="activity.timestamp"
              >
                <div v-for="content in activity.contentList" class="flex items-start">
                  <div class="mr-[8px] pt-2">
                    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="2" cy="2" r="2" fill="#475467"/></svg>
                  </div>
                  {{ content }}
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
          <div class="flex-col font-light text-[#475467] text-[20px] leading-[30px]">
            <div class="mb-[30px]">
              {{ $t('landingPage.company.Desc1') }}
            </div>
            <div>
              {{ $t('landingPage.company.Desc2') }}
            </div>
          </div>
        </div>
      </div>
      <div class="bg-[#FFF]">
        <div class="m-auto max-w-[1280px] px-[32px] md:px-[16px] pb-[96px] md:pb-[64px]">
          <img src="/images/company/company_bg.png" class="w-full relative md:bottom-[64px] bottom-[96px]" alt="OpenCSG"/>
        </div>
      </div>
    </div>
  </template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n();


const activitiesR = [
  {
    contentList: [
      t('landingPage.company.content4'),
    ],
    timestamp: t('landingPage.company.timestamp4'),
  },
  {
    contentList: [
      t('landingPage.company.content3-1'),
      t('landingPage.company.content3-2')
    ],
    timestamp: t('landingPage.company.timestamp3'),
  },
  {
    contentList: [
      t('landingPage.company.content2-1'),
      t('landingPage.company.content2-2')
    ],
    timestamp: t('landingPage.company.timestamp2'),
  },
  {
    contentList: [
      t('landingPage.company.content1-1'),t('landingPage.company.content1-2')
    ],
    timestamp: t('landingPage.company.timestamp1'),
  }
]

const activitiesL = [
  {
    contentList: [t('landingPage.company.contentL4')],
    timestamp: t('landingPage.company.timestampL4')
  },
  {
    contentList: [t('landingPage.company.contentL3')],
    timestamp: t('landingPage.company.timestampL3')
  },
  {
    contentList: [
      t('landingPage.company.contentL2-1'),
      t('landingPage.company.contentL2-2')
    ],
    timestamp: t('landingPage.company.timestampL2')
  },
  {
    contentList: [t('landingPage.company.contentL1')],
    timestamp: t('landingPage.company.timestampL1')
  }
]
</script>
<style scoped>
  :deep(.companyTimeLine .el-timeline-item__timestamp) {
    font-size:14px;
    font-weight:500;
    color:#344054;
  }
  :deep(.companyTimeLine .el-timeline-item__content) {
    font-weight:300;
    color:#475467;
  }
</style>
