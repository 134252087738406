import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "StarCloud pt-[96px] text-center w-full" }
const _hoisted_2 = { class: "max-w-[1280px] m-auto" }
const _hoisted_3 = { class: "flex flex-col items-center md:px-[20px] mb-[64px] relative" }
const _hoisted_4 = { class: "text-[36px] leading-[44px] text-[#101828]" }
const _hoisted_5 = { class: "my-[20px] max-w-[768px] text-[20px] text-[#606266] leading-[30px] text-left font-light" }
const _hoisted_6 = { class: "flex gap-[16px] mb-[16px] product" }
const _hoisted_7 = { class: "w-full bg-white pb-[48px]" }
const _hoisted_8 = { class: "max-w-[1280px] m-auto" }
const _hoisted_9 = { class: "pl-[32px] lg:px-[32px] flex items-center justify-center lg:flex-col gap-[64px] mb-[64px]" }
const _hoisted_10 = { class: "grow grid grid-cols-2 max-w-[576px] gap-[32px] py-[32px] lg:w-auto lg:grid-cols-1 items-start" }
const _hoisted_11 = { class: "flex flex-col items-start gap-[20px] md:px-[24px]" }
const _hoisted_12 = { class: "px-[12px] py-[12px] rounded-[40px] bg-[#ACBDFF] border-[8px] border-[#CED8FF]" }
const _hoisted_13 = { class: "text-[20px] leading-[30px] text-[#101828] mb-[8px]" }
const _hoisted_14 = { class: "text-[16px] leading-[24px] font-light text-[#475467]" }
const _hoisted_15 = { class: "flex flex-col items-start gap-[20px] md:px-[24px]" }
const _hoisted_16 = { class: "px-[12px] py-[12px] rounded-[40px] bg-[#ACBDFF] border-[8px] border-[#CED8FF]" }
const _hoisted_17 = { class: "text-[20px] leading-[30px] text-[#101828] mb-[8px]" }
const _hoisted_18 = { class: "text-[16px] leading-[24px] font-light text-[#475467]" }
const _hoisted_19 = { class: "flex flex-col items-start gap-[20px] md:px-[24px]" }
const _hoisted_20 = { class: "px-[12px] py-[12px] rounded-[40px] bg-[#ACBDFF] border-[8px] border-[#CED8FF]" }
const _hoisted_21 = { class: "text-[20px] leading-[30px] text-[#101828] mb-[8px]" }
const _hoisted_22 = { class: "text-[16px] leading-[24px] font-light text-[#475467]" }
const _hoisted_23 = { class: "flex flex-col items-start gap-[20px] md:px-[24px]" }
const _hoisted_24 = { class: "px-[12px] py-[12px] rounded-[40px] bg-[#ACBDFF] border-[8px] border-[#CED8FF]" }
const _hoisted_25 = { class: "text-[20px] leading-[30px] text-[#101828] mb-[8px]" }
const _hoisted_26 = { class: "text-[16px] leading-[24px] font-light text-[#475467]" }
const _hoisted_27 = { class: "grow bg-[#F2F4F7] max-w-[730px] max-h-[620px] rounded-l-[24px] lg:rounded-[24px] pr-0 xl:mt-[20px] lg:mt-0 mlg:w-full mlg:px-[32px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_VideoDialog = _resolveComponent("VideoDialog")
  const _component_el_image = _resolveComponent("el-image")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('product.starCloud.headline')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('product.starCloud.desc')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = (...args) => ($options.openDialog && $options.openDialog(...args))),
              class: "cursor-pointer py-[12px] px-[18px] flex text-[14px] leading-[20px] text-[#344054] gap-[6px] border border-[#D0D5DD] rounded-[8px]"
            }, [
              _createVNode(_component_SvgIcon, { name: "starcloud_video" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('product.starCloud.demoVideo')), 1 /* TEXT */)
            ]),
            _createVNode(_component_VideoDialog, {
              ref: "videoDialogRef",
              videoSrc: $data.videoSrc
            }, null, 8 /* PROPS */, ["videoSrc"])
          ]),
          _createVNode(_component_SvgIcon, {
            class: "absolute top-[30%] right-0 w-[20%] lg:hidden",
            name: "starcloud_demo"
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_SvgIcon, { name: "starcloud_intro_model" })
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('product.starCloud.part1')), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('product.starCloud.part1-')), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_SvgIcon, { name: "starcloud_intro_process" })
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('product.starCloud.part2')), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('product.starCloud.part2-')), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_SvgIcon, { name: "starcloud_intro_running" })
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('product.starCloud.part3')), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('product.starCloud.part3-')), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_SvgIcon, { name: "starcloud_intro_safety" })
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('product.starCloud.part4')), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('product.starCloud.part4-')), 1 /* TEXT */)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_el_image, {
              src: "/images/product/computer.png",
              "preview-src-list": ['/images/product/computer.png']
            })
          ])
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}