import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-[20px] overflow-scroll" }
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      src: $setup.endpointWithToken,
      allow: "clipboard-write",
      id: "application-space-iframe",
      frameborder: "0",
      class: "w-full h-[100vh]"
    }, null, 8 /* PROPS */, _hoisted_2)
  ]))
}