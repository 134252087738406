import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e091904"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overflow-x-auto" }
const _hoisted_2 = { class: "w-full text-sm code-preview" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["data-line-number"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.highlightContent.split('\n'), (row, index) => {
        return (_openBlock(), _createElementBlock("tr", {
          id: `L${index + 1}`,
          key: index
        }, [
          _createElementVNode("td", {
            class: "blob-line-number w-8 text-center text-gray-300 cursor-pointer hover:text-black",
            "data-line-number": index + 1
          }, null, 8 /* PROPS */, _hoisted_4),
          _createElementVNode("td", {
            class: "overflow-visible whitespace-pre px-3",
            innerHTML: row
          }, null, 8 /* PROPS */, _hoisted_5)
        ], 8 /* PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}