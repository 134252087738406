import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder"]
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      title: _ctx.$t('form.contactUs.contact'),
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dialogVisible) = $event)),
      width: "80%",
      "show-close": false,
      class: "max-w-[438px] w-[80%]"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          ref: "forms",
          onSubmit: _withModifiers($setup.sendEmail, ["prevent"])
        }, [
          _createElementVNode("input", {
            class: "formInput w-full bg-white text-[#606266] rounded-[4px] border-solid border border-[#DCDFE6] leading-[40px] px-[15px] my-[10px] outline-0",
            type: "text",
            placeholder: _ctx.$t('form.contactUs.name'),
            name: "user_name"
          }, null, 8 /* PROPS */, _hoisted_1),
          _createElementVNode("input", {
            class: "formInput w-full bg-white text-[#606266] rounded-[4px] border-solid border border-[#DCDFE6] leading-[40px] px-[15px] my-[10px] outline-0",
            type: "text",
            placeholder: _ctx.$t('form.contactUs.company'),
            name: "user_company"
          }, null, 8 /* PROPS */, _hoisted_2),
          _createElementVNode("input", {
            class: "formInput w-full bg-white text-[#606266] rounded-[4px] border-solid border border-[#DCDFE6] leading-[40px] px-[15px] my-[10px] outline-0",
            type: "email",
            placeholder: _ctx.$t('form.contactUs.email'),
            name: "user_email"
          }, null, 8 /* PROPS */, _hoisted_3),
          _createElementVNode("input", {
            class: "formInput w-full bg-white text-[#606266] rounded-[4px] border-solid border border-[#DCDFE6] leading-[40px] px-[15px] my-[10px] outline-0",
            type: "tel",
            placeholder: _ctx.$t('form.contactUs.phone'),
            name: "user_tel"
          }, null, 8 /* PROPS */, _hoisted_4),
          _createElementVNode("input", {
            class: "formInput w-full bg-white text-[#606266] rounded-[4px] border-solid border border-[#DCDFE6] leading-[40px] px-[15px] my-[10px] outline-0",
            type: "text",
            placeholder: _ctx.$t('form.contactUs.job'),
            name: "user_job"
          }, null, 8 /* PROPS */, _hoisted_5),
          _createElementVNode("textarea", {
            name: "message",
            class: "w-full bg-white text-[#606266] rounded-[4px] border leading-normal border-solid border-[#DCDFE6] py-[5px] px-[15px] outline-0",
            placeholder: _ctx.$t('form.contactUs.help')
          }, null, 8 /* PROPS */, _hoisted_6),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("input", {
              class: "w-[70px] text-white bg-[#409EFF] rounded-[4px] leading-[40px] mr-20 cursor-pointer",
              type: "submit",
              value: _ctx.$t('form.contactUs.submit')
            }, null, 8 /* PROPS */, _hoisted_8),
            _createElementVNode("div", {
              class: "inline-block w-[70px] text-[#606266] bg-white border border-solid border-[#DCDFE6] rounded-[4px] leading-[40px] cursor-pointer",
              onClick: _cache[0] || (_cache[0] = $event => ($setup.dialogVisible = false))
            }, _toDisplayString(_ctx.$t('form.contactUs.close')), 1 /* TEXT */)
          ])
        ], 544 /* NEED_HYDRATION, NEED_PATCH */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "modelValue"])
  ]))
}