import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Hero"]),
    _createVNode($setup["Feature"]),
    _createVNode($setup["LatestNews"]),
    _createVNode($setup["JoinUs"]),
    _createVNode($setup["Company"])
  ], 64 /* STABLE_FRAGMENT */))
}