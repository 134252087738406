export const csghub = {
  "banner": {
    "headline1": "开源开放的",
    "headline2": "针对大模型原生设计的资产管理平台",
    "solution": "为用户创造一个安全、高效且可信赖的环境，使其能够更好的管理和运用大模型核心资产数据",
    "join": "加入社区"
  },
  "product": {
    "introduce": "产品介绍",
    "headline": "智能数据与AI管理平台",
    "desc": "提供全面的解决方案，帮助您高效管理资产、开发AI应用、同步多源数据以及确保数据安全合规。探索我们的功能，发现更多可能性，推动您的AI项目迈向成功",
    "part1": "全面的资产管理系统",
    "part1-": "高效管理模型库、数据集和代码等资产。支持大文件，提供Web端在线编辑功能，并可以预览数据集。通过资产元数据管理，使您的数据管理更有序、更高效",
    "part1-p1": "模型库、数据集、代码等资产管理",
    "part1-p2": "大文件支持",
    "part1-p3": "Web 端在线编辑",
    "part1-p4": "数据集预览",
    "part1-p5": "资产元数据管理",
    "part2": "高效的AI应用开发平台",
    "part2-": "在我们的平台上，您可以快速展示模型的能力，轻松搭建并测试应用原型，极大提升开发效率。支持一键启动模型推理服务和模型微调训练，让AI应用开发变得更简单",
    "part2-p1": "在应用内快速展示模型的能力",
    "part2-p2": "搭建并测试应用原型",
    "part2-p3": "一键启动模型推理服务",
    "part2-p4": "一键启动模型微调训练",
    "part3": "多源数据与模型同步",
    "part3-": "通过我们的平台，您可以查看OpenCSG传神社区的源数据，并获得模型和数据集推荐。同时，支持同步OpenCSG传神社区的模型和数据集，使您的资源管理更便捷",
    "part3-p1": "查看 OpenCSG 传神社区源数据",
    "part3-p2": "模型/数据集推荐",
    "part3-p3": "同步 OpenCSG 传神社区模型和数据集",
    "part4": "完善的安全合规保障",
    "part4-": "我们提供自定义资产元数据功能，确保模型和License的合规性溯源与验证。通过数据完整性校验机制、提供高可用性和灾难恢复方案，确保您的数据安全无忧",
    "part4-p1": "自定义资产元数据",
    "part4-p2": "模型和 License 合规性溯源与验证",
    "part4-p3": "数据集完整性校验机制",
    "part4-p4": "高可用",
    "part4-p5": "灾难恢复",
    "feature-h": "CSGHub 产品特点",
    "feature-p": "为用户创造一个安全、高效且可信赖的环境，使其能够更好的管理和运用大模型核心资产数据",
    "feature-button": "预约产品演示",
    "feature-h1": "资产统一管理",
    "feature-p1": "一站式Hub统一管理模型文件、数据集、大模型应用代码。同时支持 HTTPS 和 SSH 协议的 Git 命令和 Web 界面操作",
    "feature-h2": "大模型能力扩展",
    "feature-p2": "原生支持版本化管理、模型格式转化、数据自动预处理、数据集预览等功能",
    "feature-h3": "权限与安全",
    "feature-p3": "支持与企业用户系统集成、支持资产可见范围设置、外内部接口鉴权设计，满足企业安全需求",
    "feature-h4": "私有化部署支持",
    "feature-p4": "无互联网依赖、无云厂商依赖等外部依赖，可一键启动私有化部署",
  },
  "price": {
    "introduce": "价格",
    "headline": "选择适合您的版本",
    "desc": "简单、透明的定价",
    "community": "社区版",
    "community-p1": "适合个人或小团队的版本",
    "asset": "资产管理模块",
    "asset-p1": "模型管理",
    "asset-p2": "数据集管理",
    "asset-p3": "代码仓库管理",
    "asset-p4": "大文件支持",
    "asset-p5": "Web 端在线编辑",
    "asset-p6": "数据集查看",
    "asset-p7": "资产元数据管理机制",
    "asset-p8": "基础管理员后台",
    "ai": "AI 应用",
    "ai-p1": "应用空间",
    "ai-p2": "一键启动模型推理服务",
    "ai-p3": "一键启动模型微调训练",
    "ai-p4": "数据格式转化",
    "ai-p5": "资产 Copilot",
    "data": "多源数据同步",
    "data-p1": "同步 OpenCSG 传神社区数据集（受限）",
    "data-p2": "同步 OpenCSG 传神社区模型（受限）",
    "data-p3": "查看 OpenCSG 传神社区源数据",
    "portal": "社区支持",
    "portal-p1": "通过 OpenCSG 社区或者 GitHub 获得社区支持", 
    "company": "企业版",
    "company-desc": "包含社区版之外更高级的功能和支持服务",
    "unit": "/年/用户",
    "aiPlus": "AI 应用增强",
    "aiPlus-p1": "模型推理优化",
    "aiPlus-p2": "私有化模型资产 Copilot",
    "dataCompany": "多源数据同步",
    "dataCompany-p1": "模型/数据集推荐",
    "dataCompany-p2": "同步 OpenCSG 传神社区模型",
    "dataCompany-p3": "同步 OpenCSG 传神社区数据集",
    "safe": "安全合规",
    "safe-p1": "自定义资产元数据",
    "safe-p2": "数据完整性校验机制",
    "safe-p3": "高可用",
    "safe-p4": "灾难恢复",
    "safe-p5": "模型和 License 合规性溯源与验证",
    "made": "定制能力",
    "made-p1": "企业级细粒度权限控制",
    "made-p2": "企业级管理后台",
    "made-p3": "界面定制，8000/人天",
    "technology": "技术支持",
    "technology-p1": "OpenCSG 官方工单系统，7x24在线服务",
    "technology-p2": "专家支持服务（远程+现场），8000/人天",
    "capacity": "资源容量",
    "capacity-p1": "同步模型 100 个",
    "free": "免费使用",
    "console": "联系销售",
    "recommend": "推荐",
    "month": "月付",
    "year": "年付"
  },
  "serve": {
    "recommend": "在线体验",
    "headline": "线上 SaaS 快速体验",
    "desc": "在OpenCSG大模型社区与百万用户共同成长",
    "doc": "文档中心",
    "register": "注册使用"
  }
}
