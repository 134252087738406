import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center mt-[50px] lg:mt-[60px] md:mt-[60px] sm:mt-[36px]" }
const _hoisted_2 = { class: "flex flex-col bg-white w-[766px] lg:w-[566px] md:w-[90%] h-auto border rounded-lg p-[36px] gap-y-[24px] border-[#DCDFE6] bg-gradient-to-r shadow-xl" }
const _hoisted_3 = { class: "text-[#303133] text-sm gap-y-[48px]" }
const _hoisted_4 = { class: "w-full h-auto" }
const _hoisted_5 = { class: "hidden md:block text-sm text-[#606266] mb-[24px]" }
const _hoisted_6 = { class: "mt-[16px]" }
const _hoisted_7 = { class: "mt-[16px]" }
const _hoisted_8 = { class: "flex md:flex-col md:gap-y-[20px] gap-x-[80px]" }
const _hoisted_9 = { class: "w-[320px] lg:w-full" }
const _hoisted_10 = { class: "w-[320px] lg:w-full md:flex-col" }
const _hoisted_11 = { class: "w-[306px] mt-[24px] lg:w-full md:flex-col" }
const _hoisted_12 = { class: "w-full h-auto mt-[24px]" }
const _hoisted_13 = { class: "flex flex md:flex-col md:gap-y-[20px] gap-x-[80px] gap-x-[80px]" }
const _hoisted_14 = { class: "w-[320px] lg:w-full md:flex-col" }
const _hoisted_15 = { class: "w-[320px] lg:w-full md:flex-col" }
const _hoisted_16 = { class: "flex flex md:flex-col md:gap-y-[20px] gap-x-[80px] gap-x-[80px]" }
const _hoisted_17 = { class: "w-[320px] mt-[24px] lg:w-full md:flex-col" }
const _hoisted_18 = { class: "w-[320px] mt-[24px] md:mt-0 lg:w-full md:flex-col" }
const _hoisted_19 = { class: "w-full h-auto mt-[24px]" }
const _hoisted_20 = { class: "w-full" }
const _hoisted_21 = { class: "flex gap-x-[16px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_form_label = _resolveComponent("form-label")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('form.partner.p1')), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('form.partner.p2')), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_form_label, {
                labelName: _ctx.$t('form.partner.name'),
                required: true
              }, null, 8 /* PROPS */, ["labelName"]),
              _createVNode(_component_el_input, {
                modelValue: $data.userName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.userName) = $event)),
                clearable: "",
                placeholder: _ctx.$t('form.partner.name-'),
                class: "w-full h-[40px] text-[#606266]"
              }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_form_label, {
                labelName: _ctx.$t('form.partner.phone'),
                required: true
              }, null, 8 /* PROPS */, ["labelName"]),
              _createVNode(_component_el_input, {
                modelValue: $data.phone,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.phone) = $event)),
                clearable: "",
                placeholder: _ctx.$t('form.partner.phone'),
                class: "w-full h-[40px] text-[#606266]"
              }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_form_label, {
              labelName: _ctx.$t('form.partner.email'),
              required: true
            }, null, 8 /* PROPS */, ["labelName"]),
            _createVNode(_component_el_input, {
              modelValue: $data.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.email) = $event)),
              clearable: "",
              placeholder: "example@gmail.com",
              class: "w-full h-[40px] text-[#606266]"
            }, null, 8 /* PROPS */, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_form_label, {
                labelName: _ctx.$t('form.partner.company'),
                required: true
              }, null, 8 /* PROPS */, ["labelName"]),
              _createVNode(_component_el_input, {
                modelValue: $data.companyName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.companyName) = $event)),
                clearable: "",
                placeholder: _ctx.$t('form.partner.company-'),
                class: "w-full h-[40px] text-[#606266]"
              }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_form_label, {
                labelName: _ctx.$t('form.partner.expertise'),
                required: true
              }, null, 8 /* PROPS */, ["labelName"]),
              _createVNode(_component_el_input, {
                modelValue: $data.profession,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.profession) = $event)),
                clearable: "",
                placeholder: _ctx.$t('form.partner.expertise-'),
                class: "w-full h-[40px] text-[#606266]"
              }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_form_label, {
                labelName: _ctx.$t('form.partner.web'),
                required: true
              }, null, 8 /* PROPS */, ["labelName"]),
              _createVNode(_component_el_input, {
                modelValue: $data.companyWeb,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.companyWeb) = $event)),
                clearable: "",
                placeholder: _ctx.$t('form.partner.web-'),
                class: "w-full h-[40px] text-[#606266]"
              }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_form_label, {
                labelName: _ctx.$t('form.partner.industry'),
                required: true
              }, null, 8 /* PROPS */, ["labelName"]),
              _createVNode(_component_el_input, {
                modelValue: $data.industry,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.industry) = $event)),
                clearable: "",
                placeholder: _ctx.$t('form.partner.industry-'),
                class: "w-full h-[40px] text-[#606266]"
              }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_form_label, {
              labelName: _ctx.$t('form.partner.desc'),
              required: true
            }, null, 8 /* PROPS */, ["labelName"]),
            _createVNode(_component_el_input, {
              modelValue: $data.desc,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.desc) = $event)),
              clearable: "",
              placeholder: _ctx.$t('form.partner.desc-'),
              type: "textarea",
              class: "w-full h-auto text-[#606266]"
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: $options.submitTheForm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('form.partner.submit')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ])
  ]))
}