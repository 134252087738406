import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa048271"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "max-w-[1280px] m-auto" }
const _hoisted_3 = { class: "py-[96px] text-center" }
const _hoisted_4 = { class: "text-[#223B99] text-[16px] font-[500] leading-[24px]" }
const _hoisted_5 = { class: "mt-[12px] text-[48px] font-[500] leading-[60px] tracking-[-0.96px]" }
const _hoisted_6 = { class: "mt-[24px] text-[20px] font-[300] leading-[30px]" }
const _hoisted_7 = {
  key: 0,
  class: "mt-[24px]"
}
const _hoisted_8 = {
  href: "/daily_papers/new",
  class: "inline-block text-center px-[18px] py-[12px] text-[16px] text-white bg-[#3250BD] rounded-[8px] border-[1px] border-[#3250BD]"
}
const _hoisted_9 = { class: "mt-[64px] gap-[64px] flex-col md:px-[16px]" }
const _hoisted_10 = { class: "flex justify-center mb-[64px]" }
const _hoisted_11 = { class: "w-[280px] md:w-full" }
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = { class: "flex gap-x-[32px] gap-y-[48px] flex-wrap justify-between border-b border-[#EAECF0] pb-[64px]" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "flex justify-between gap-[16px] mt-[20px] items-start" }
const _hoisted_17 = { class: "text-[24px] font-[500] leading-[32px] text-left line-clamp line-clamp-2 text-[#101828] h-[64px]" }
const _hoisted_18 = { class: "w-[24px]" }
const _hoisted_19 = { class: "mt-[8px] text-[16px] font-[300] leading-[24px] text-left line-clamp line-clamp-2 md:line-clamp-3 text-[#475467]" }
const _hoisted_20 = ["href"]
const _hoisted_21 = { class: "flex mt-[24px] gap-[12px] items-center" }
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "flex flex-col justify-between items-start" }
const _hoisted_24 = { class: "text-[14px] font-[500] leading-[20px] text-[#101828]" }
const _hoisted_25 = { class: "text-[14px] font-[300] leading-[24px] text-[#475467]" }
const _hoisted_26 = { class: "mt-[20px] flex justify-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_search = _resolveComponent("search")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_input = _resolveComponent("el-input")
  const _component_TopRight = _resolveComponent("TopRight")
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('dailyPaper.paper')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('dailyPaper.eachpaper')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('dailyPaper.arxiv')), 1 /* TEXT */),
        ($props.canCreateDailyPaper)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('dailyPaper.newpaper')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_el_input, {
                style: {"width":"100%"},
                size: "large",
                modelValue: $setup.searchForm.keywords,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.searchForm.keywords) = $event)),
                placeholder: _ctx.$t('dailyPaper.placeholder'),
                clearable: "",
                "suffix-icon": "el-icon-search",
                onKeyup: _withKeys($setup.handleSearch, ["enter"])
              }, {
                prefix: _withCtx(() => [
                  _createVNode(_component_el_icon, {
                    onClick: $setup.handleSearch,
                    class: "el-input__icon"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_search)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "placeholder"])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse($props.dailyPapers), (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "w-[405px] md:w-full",
                  key: item.daily_paper.uuid
                }, [
                  _createElementVNode("div", {
                    class: "flex w-full flex-col cursor-pointer",
                    onClick: $event => ($setup.visitDetail(item.daily_paper.uuid))
                  }, [
                    _createElementVNode("img", {
                      src: item.daily_paper.cover_url,
                      alt: "",
                      class: "h-[240px] object-contain"
                    }, null, 8 /* PROPS */, _hoisted_15),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, _toDisplayString(item.daily_paper.title), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_el_icon, { size: 24 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TopRight)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(item.daily_paper.recommendation), 1 /* TEXT */),
                    _createElementVNode("a", {
                      href: item.recommender_url
                    }, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("img", {
                          src: item.daily_paper.recommender_avatar_url || 'https://cdn.casbin.org/img/casbin.svg',
                          alt: "",
                          class: "h-[40px] w-[40px] rounded-full"
                        }, null, 8 /* PROPS */, _hoisted_22),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, _toDisplayString(item.daily_paper.recommender_name), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_25, _toDisplayString($setup.dayjs(item.daily_paper.created_at).format("YYYY.M.D HH:mm")), 1 /* TEXT */)
                        ])
                      ])
                    ], 8 /* PROPS */, _hoisted_20)
                  ], 8 /* PROPS */, _hoisted_14)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createVNode(_component_el_pagination, {
                background: "",
                "hide-on-single-page": "",
                "current-page": $setup.searchForm.page,
                "onUpdate:currentPage": [
                  _cache[1] || (_cache[1] = $event => (($setup.searchForm.page) = $event)),
                  _cache[2] || (_cache[2] = $event => ($setup.handleSearch('PageClick')))
                ],
                "default-current-page": 1,
                "page-size": 9,
                layout: "prev, pager, next",
                total: Number($props.totalCount),
                onCurrentPage: $setup.handleSearch
              }, null, 8 /* PROPS */, ["current-page", "total"])
            ])
          ])
        ])
      ])
    ])
  ]))
}