import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-[#F5F7FA] p-[12px] rounded-[8px] mt-[16px] flex flex-col gap-[8px]" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "text-[#303133] text-[16px] font-[500]" }
const _hoisted_4 = { class: "flex items-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString($props.tokenName), 1 /* TEXT */),
      _createVNode(_component_el_button, {
        type: "info",
        onClick: $setup.confirmRefreshAccessToken
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('accessToken.starshipRefresh')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_input, {
        modelValue: $setup.theTokenValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.theTokenValue) = $event)),
        type: "password",
        "show-password": "",
        onfocus: "this.blur()"
      }, null, 8 /* PROPS */, ["modelValue"]),
      _createElementVNode("div", {
        class: "ml-[8px] px-[16px] py-[8px] border rounded-[4px] bg-[#FFF] cursor-pointer",
        onClick: $setup.copyToken
      }, [
        _createVNode($setup["SvgIcon"], { name: "copy" })
      ])
    ])
  ]))
}